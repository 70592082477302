import { reactive } from "@vue/reactivity";
import { CreateInfo, Module } from "../../../../shared/protocol/Create";
import StorageService from "./StorageService";
import Router from "../../router/index";

export type CreateGuardStage = 'title' | 'modules' | 'setup' | 'done';

class CreateService {
    info: CreateInfo = reactive({
        title: '',
        auth: 'simple',
        modules: {
            date: true,
            discuss: false,
            vote: false,
            map: false,
            description: false,
            checklist: false
        },
        config: {},
    });

    configState: {[key: string]: boolean} = {};

    public readonly baseStepCount = 2; //Steps necessary before module setup

    reset() {
        this.info.title = '';
        this.info.auth = 'simple';
        for(const key in this.info.modules) {
            (this.info.modules as any)[key] = false;
        }
        this.info.config = {};
        this.clearState();
        console.log("Reset");
    }

    public countSelectedModules(): number {
        return Object.values(this.info.modules).filter(e => e===true).length;
    }

    public checkGuard(stage: CreateGuardStage): boolean {
        switch(stage) {
            case 'title':
                return true;
            case 'modules':
                return this.info.title.length >= 2 && this.info.title.length <= 30;
            case 'setup':
                return this.checkGuard('modules') 
                        && Object.values(this.info.modules).includes(true);
            case 'done':
                return this.checkGuard('modules')
                && this.checkGuard('setup')
                && Object.keys(this.info.config).length > 0
                && Object.keys(this.info.config).length === Object.values(this.configState).filter(v => !!v).length;
        }
    }

    toggleModuleSelected(module: Module): boolean {
        if(this.info.modules[module]) {
            delete this.info.config[module];
        }
        this.info.modules[module] = !this.info.modules[module];
        this.saveState();
        return this.info.modules[module];
    }

    public restoreState() {
        StorageService.getLocalStorage('create_config').then(value => {
            if(value !== null) {
                const data = JSON.parse(value) as CreateInfo;
                this.info.title = data.title;
                this.info.modules = data.modules;
                this.info.config = data.config;
            }
        });
    }

    public saveState() {
        StorageService.setLocalStorage('create_config', JSON.stringify(this.info));
    }

    public clearState() {
        StorageService.clearLocalStorage('create_config');
        this.configState = {};
    }

    public calculateSteps(): number {
        return this.baseStepCount + this.countSelectedModules();
    }

    public countConfiguredModules(): number {
        return Object.keys(this.info.config).length;
    }

    public configureNext() {
        for(const key of Object.keys(this.info.modules)) {
            if(this.info.modules[key as Module] && !this.configState[key]) {
                this.configure(key as Module);
                return;
            }
        }
        this.configureDone();
    }

    public resetConfigState() {
        for(const key in this.configState) {
            this.configState[key] = false;
        }
    }

    public configure(module: Module) {
        Router.push(`/create/setup/${module}`);
    }

    public configureDone() {
        if(this.countConfiguredModules() < this.countSelectedModules()) {
            console.log("Done next");
            this.configureNext();
        } else {
            Router.push('/create/done');
        }
    }
}

export default new CreateService;