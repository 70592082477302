import { ModuleLanguageDefinition } from "../../LanguageService";

const def: ModuleLanguageDefinition = {
    title: [
        'Date voting',
        'Terminabstimmung'
    ],
    desc: [
        'Let the participants vote for dates or times to find the perfect time for meeting up.',
        'Lassen sie die Teilnehmer über Datum und Uhrzeit abstimmen, um den besten Zeitpunkt zu finden.'
    ],
    withTime: [
        'With time',
        'Mit Uhrzeit'
    ],
    dates: [
        'Dates',
        'Termine'
    ],
    addDate: [
        'Add date',
        'Termin hinzufügen'
    ],
    date: [
        'Date',
        'Datum'
    ],
    time: [
        'Time',
        'Uhrzeit'
    ],
    day0: [
        'Sunday',
        'Sonntag'
    ],
    day1: [
        'Monday',
        'Montag'
    ],
    day2: [
        'Tuesday',
        'Dienstag'
    ],
    day3: [
        'Wednesday',
        'Mittwoch',
    ],
    day4: [
        'Thursday',
        'Donnerstag'
    ],
    day5: [
        'Friday',
        'Freitag'
    ],
    day6: [
        'Saturday',
        'Samstag'
    ],
    noDates: [
        'No dates added yet.',
        'Noch keine Termine.'
    ],
    tooFewDates: [
        'Add at least 2 dates.',
        'Fügen sie mindestens zwei Termine hinzu.'
    ],
    titleTooShort: [
        'The title is too short.',
        'Der Titel ist zu kurz.'
    ],
    titleLabel: [
        'What is the title of the date voting?',
        'Wie lautet der Titel der Terminabstimmung?'
    ],
    noVotes: [
        'No votes for this date yet.',
        'Keine Stimmen für diesen Termin vorhanden.'
    ],
    yes: [
        'Agreed',
        'Zugesagt'
    ],
    maybe: [
        'Unsure',
        'Unsicher'
    ],
    no: [
        'Disagreed',
        'Abgesagt'
    ],
    total: [
        'Total',
        'Gesamt'
    ],
    you: [
        'You',
        'Sie'
    ],
    disagree: [
        'Decline',
        'Absagen'
    ],
    agree: [
        'Accept',
        'Zusagen'
    ],
    more: [
        'More',
        'Mehr'
    ]
}

export default def;