
import { defineComponent } from 'vue'

import { IonProgressBar, IonChip, alertController } from "@ionic/vue"

import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
    name: 'MapView',
    components: {
        IonProgressBar,
        IonChip
    },
    props: {
        query: {
            type: String,
            required: true
        },
        zoom: {
            type: Number,
            default: 10
        },
        disabled: {
            type: Boolean
        }
    },
    data: () => ({
        loading: true,
        lang: LanguageService.getTranslator('module/map')
    }),
    computed: {
        url() {
            return `https://maps.google.com/maps?q=${this.query}&t=&z=${this.zoom}&ie=UTF8&iwloc=&output=embed`;
        }
    },
    watch: {
        query: {
            handler() {
                this.showLoading();
            }
        },
        zoom: {
            handler() {
                this.showLoading();
            }
        }
    },
    methods: {
        showLoading() {
            this.loading = true;
        },
        hideLoading() {
            this.loading = false;
        },
        async showPreviewHint() {
            const alert = await alertController.create({
                header: this.lang.t('title'),
                subHeader: this.lang.t('preview'),
                message: this.lang.t('previewHint'),
                buttons: ['OK']
            });
            await alert.present();
        },
        handleTap() {
            if(this.disabled) {
                this.showPreviewHint();
            }
        }
    }
})
