import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    share: [
        'Share',
        'Teilen'
    ],
    shareUrl: [
        'Share Link',
        'Link teilen'
    ],
    copy: [
        'Copy',
        'Kopieren'
    ],
    qrCode: [
        'QR-Code',
        'QR-Code'
    ],
    urlCopied: [
        'The link has been copied to the clipboard.',
        'Der Link wurde in die Zwischenablage kopiert.'
    ],
    info: [
        'Send this link to the participants to let them take part.',
        'Senden sie diesen Link an die Teilnehmer.'
    ],
    cannotShare: [
        'Sharing the link directly is not supported on your device. Please copy the link instead.',
        'Den Link dierekt zu teilen ist auf ihrem Gerät nicht untersützt. Bitte kopieren sie den Link stattdessen.'
    ],
    error: [
        'Error',
        'Fehler'
    ]
}

export default def;