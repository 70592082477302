
import { computed, defineComponent, onBeforeMount, Ref, ref } from "vue";

import { checkmark, caretDown, add, trash } from "ionicons/icons";

import {
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonChip,
  IonIcon,
  IonButtons,
  IonButton,
  IonInput,
  IonBadge,
  IonSpinner,
  popoverController,
  alertController,
  modalController,
} from "@ionic/vue";

import EventModule from "../EventModule.vue";
import ChecklistUserList from "./ChecklistUserList.vue";

import EventService from "../../../lib/service/EventService";
import WebSocketClientService from "../../../net/WebSocketClientService";
import {
  ChecklistAddRequest,
  ChecklistCheckRequest,
  ChecklistRemoveRequest,
} from "../../../../../shared/protocol/request/Checklist";
import { ChecklistEntry } from "../../../../../shared/protocol/Checklist";
import LanguageService from "../../../lib/service/LanguageService";
import PlatformService from "../../../lib/service/PlatformService";

export default defineComponent({
  name: "ChecklistModule",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonChip,
    IonIcon,
    IonButtons,
    IonInput,
    IonButton,
    IonBadge,
    IonSpinner,
    IonCheckbox,
    EventModule,
  },
  props: {
    example: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const lang = LanguageService.getTranslator("module/checklist");
    const loading = ref(-1);
    const examples: Ref<any> = ref(undefined);
    const taskInput = ref("");
    const needInput = ref(1);
    const addLoading = ref(false);

    const disabled = computed(
      () => !WebSocketClientService.status.online && false
    );

    const userAddEnabled = computed(() => {
      return props.example !== undefined
        ? false
        : EventService.data.event !== undefined &&
            EventService.data.event.static.config.checklist !== undefined &&
            EventService.data.event.static.config.checklist.userAdd;
    });

    const entries = computed<ChecklistEntry[]>(() => {
      if (props.example) {
        return examples.value[LanguageService.lang].entries;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.checklist !== undefined
      ) {
        return EventService.data.event.static.config.checklist.entries;
      } else {
        return [];
      }
    });

    const taskExists = (title: string) => {
      title = title.toLowerCase().trim();
      for (const task of entries.value as ChecklistEntry[]) {
        if (task.title.toLowerCase() === title) return true;
      }
      return false;
    };

    const taskInputValid = computed(() => {
      const validate = taskInput.value.trim();
      return (
        validate.length >= 2 &&
        validate.length <= 30 &&
        needInput.value >= 1 &&
        needInput.value <= 100 &&
        !taskExists(validate)
      );
    });

    const votes = computed<string[][]>(() => {
      if (props.example) {
        return examples.value[LanguageService.lang].votes;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.checklist !== undefined
      ) {
        return EventService.data.event.dynamic.checklist.votes;
      } else {
        return [];
      }
    });

    const hasChecked = (index: number) => {
      if (props.example) {
        return Math.random() > 0.5;
      } else {
        return (
          EventService.data.event !== undefined &&
          EventService.data.event.dynamic.checklist !== undefined &&
          EventService.data.event.dynamic.checklist.votes[index].includes(
            EventService.data.name
          )
        );
      }
    };

    const removeEntry = async (index: number, item: ChecklistEntry) => {
      const alert = await alertController.create({
        header: lang.t("deleteHeader"),
        subHeader: item.title,
        message: lang.t("confirmDelete"),
        buttons: [
          {
            text: lang.t("abort"),
            role: "cancel",
          },
          {
            text: lang.t("confirm"),
            role: "destructive",
          },
        ],
      });
      await alert.present();
      const result = await alert.onDidDismiss();
      if (result.role === "destructive") {
        const req: ChecklistRemoveRequest = {
          index,
        };
        await WebSocketClientService.sendRequest("checklist:remove", req);
      }
    };

    const ownsEntry = (item: ChecklistEntry) =>
      item.user !== undefined && item.user === EventService.data.name;

    const isDone = (index: number) =>
      votes.value[index].length >= entries.value[index].need;

    const addEntry = () => {
      if (taskInputValid.value) {
        addLoading.value = true;
        const req: ChecklistAddRequest = {
          title: taskInput.value.trim(),
          need: needInput.value,
        };
        WebSocketClientService.sendRequest("checklist:add", req).then(() => {
          taskInput.value = "";
          addLoading.value = false;
        });
      }
    };

    const check = async (index: number) => {
      loading.value = index;
      const req: ChecklistCheckRequest = {
        index,
      };
      WebSocketClientService.sendRequest("checklist:check", req).then(() => {
        loading.value = -1;
      });
    };

    const showList = async (index: number, event: Event) => {
      if (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.checklist !== undefined &&
        EventService.data.event.static.config.checklist
      ) {
        if (PlatformService.data.large) {
          const popover = await popoverController.create({
            component: ChecklistUserList,
            componentProps: {
              title:
                EventService.data.event.static.config.checklist.entries[index]
                  .title,
              users: EventService.data.event.dynamic.checklist.votes[index],
              index,
            },
            event,
          });
          await popover.present();
        } else {
          const modal = await modalController.create({
            component: ChecklistUserList,
            componentProps: {
              title:
                EventService.data.event.static.config.checklist.entries[index]
                  .title,
              users: EventService.data.event.dynamic.checklist.votes[index],
              index,
            },
          });
          await modal.present();
        }
      }
    };

    onBeforeMount(() => {
      if (props.example) {
        examples.value = require("./example").default;
      }
    });

    return {
      checkmark,
      caretDown,
      add,
      trash,
      lang,
      EventService,
      loading,
      examples,
      taskInput,
      needInput,
      addLoading,
      disabled,
      userAddEnabled,
      taskInputValid,
      entries,
      votes,
      hasChecked,
      removeEntry,
      ownsEntry,
      taskExists,
      isDone,
      addEntry,
      check,
      showList,
    };
  },
});
