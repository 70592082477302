import { reactive } from "vue";
import StorageService from "./StorageService";

class DesignService {

    design = reactive({
        dark: false
    });

    constructor() {
        StorageService.getLocalStorage('design_dark', 'false').then(value => {
            this.setDarkMode(value === 'true');
        });
    }

    setDarkMode(status: boolean) {
        this.design.dark = status;
        if(status) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
        StorageService.setLocalStorage('design_dark', `${status}`);
    }
}

export default new DesignService;