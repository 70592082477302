
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import StorageService from "./lib/service/StorageService";
import HTTPClientService from "./net/HTTPClientService";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import LanguageService from "./lib/service/LanguageService";
import NotificationService from "./lib/service/NotificationService";
import EventService from "./lib/service/EventService";
import PlatformService from "./lib/service/PlatformService";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  data: () => ({
    HTTPClientService,
    LanguageService,
  }),
  beforeCreate() {
    StorageService.init();
  },
  mounted() {
    PlatformService.getShowIntro().then((value) => {
      if (!value) {
        setTimeout(() => {
          SplashScreen.hide();
        }, 300);
      }
    });
    NotificationService.init();
  },
  beforeMount() {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      if (event.url.includes("/a#") && event.url.split("a#")[1].length === 24) {
        EventService.join(event.url.split("a#")[1]);
      }
    });
  },
});
