import { VoteOption } from "../../../../../shared/protocol/Vote";

interface VoteExample {
    options: VoteOption[];
    votes: string[][];
}

const example: {[key in 'de' | 'en']: VoteExample} = {
    de: {
        options: [
            {
                title: 'Pizza'
            },
            {
                title: 'Lasange'
            },
            {
                title: 'Raclette'
            },
            {
                title: 'Pfannekuchen'
            }
        ],
        votes: [
            ['Max', 'Erika'],
            ['Max'],
            ['Peter', 'Susanne', 'Heinz', 'Sabine'],
            ['Niklas', 'Anna']
        ]
    },
    en: {
        options: [
            {
                title: 'Pizza'
            },
            {
                title: 'Lasange'
            },
            {
                title: 'Raclette'
            },
            {
                title: 'Pancakes'
            }
        ],
        votes: [
            ['Max', 'Erika'],
            ['Max'],
            ['Peter', 'Susanne', 'Heinz', 'Sabine'],
            ['Niklas', 'Anna']
        ]
    }
}

export default example;