
import { defineComponent, onMounted, Ref, ref } from "vue";

export default defineComponent({
  name: "FeatureEntry",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const visible = ref(false);
    const content: Ref<null | HTMLElement> = ref(null);

    onMounted(() => {
      setTimeout(() => {
        if (content.value !== null) {
          const observer = new IntersectionObserver((ev) => {
            if (!visible.value && ev[0].isIntersecting) {
              visible.value = true;
              observer.disconnect();
            }
          });
          observer.observe(content.value);
        }
      }, 1000);
    });
    return {
      visible,
      content,
    };
  },
});
