
import { computed, defineComponent } from "vue";

import { checkmark, close, help } from "ionicons/icons";

import { IonList, IonItem, IonLabel, IonIcon, IonListHeader } from "@ionic/vue";
import EventService from "../../../lib/service/EventService";
import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
  name: "VotersList",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonListHeader,
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const lang = LanguageService.getTranslator("module/date");

    const votes = computed<{ y: string[]; n: string[]; m: string[] }>(() => {
      if (EventService.data.event && EventService.data.event.dynamic.date) {
        return EventService.data.event.dynamic.date.votes[props.index];
      } else {
        return { y: [], n: [], m: [] };
      }
    });

    const noVotes = computed(() => {
      return (
        votes.value.y.length <= 0 &&
        votes.value.m.length <= 0 &&
        votes.value.n.length <= 0
      );
    });

    return {
      lang,
      checkmark,
      close,
      help,
      votes,
      noVotes,
    };
  },
});
