import WebSocketClientService from "@/net/WebSocketClientService";
import { Capacitor } from "@capacitor/core";
import { loadingController, modalController } from "@ionic/core";
import { reactive } from "vue";
import { GetSetupInfoResult } from "../../../../server/src/db/Manager";
import { AnswerMessage } from "../../../../shared/protocol/Communication";
import EventData from "../../../../shared/protocol/EventData";
import { GetRequest } from "../../../../shared/protocol/request/Get";
import NameInputModal from "../../components/main/NameInputModal.vue";
import NotificationService from "./NotificationService";
import StorageService from "./StorageService";
import { NotificationToggleRequest } from "../../../../shared/protocol/request/Notifications";
import LanguageService, { Translator } from "./LanguageService";
import Router from "../../router/index";
import HTTPClientService from "@/net/HTTPClientService";

interface AuthInputResult {
    name: string;
    notifications: boolean;
}

class EventService {
    data = reactive({
        event: undefined as (undefined | EventData),
        online: [] as string[],
        name: '',
        auth: undefined as (undefined | AuthInputResult),
        id: ''
    });

    private readonly lang: Translator;

    constructor() {
        this.lang = LanguageService.getTranslator('main/service');
    }


    async load(eventId: string, auth: AuthInputResult) {
        console.log("Loading", eventId);
        await HTTPClientService.configLoaded;
        this.data.name = auth.name;
        this.data.id = eventId;
        this.data.auth = auth;
        const loader = await loadingController.create({
            message: this.lang.t('loading')
        });
        await loader.present();

        const response = await WebSocketClientService.sendRequest('get', { id: eventId, name: auth.name, notificationToken: (auth.notifications && Capacitor.getPlatform() !== 'web') ? NotificationService.firebaseToken : undefined } as GetRequest);
        if (response !== undefined) {
            const answer = response as AnswerMessage;
            this.data.event = answer.data as EventData;
            StorageService.addRecent({ id: eventId, name: auth.name, title: this.data.event.static.title, time: Date.now() / 60000 });
            Router.push(`/a#${eventId}`);
            await loadingController.dismiss();
            console.log("GOT FROM LOAD", eventId);
        } else {
            console.error("Load error");
        }
    }

    async join(eventId: string) {
        console.log("Joining", eventId);
        const auth = await this.getAuth(eventId);
        this.load(eventId, auth);
    }

    reload() {
        if (this.data.auth) {
            this.load(this.data.id, this.data.auth);
        }
    }

    async getAuth(eventId: string): Promise<AuthInputResult> {
        const read = await StorageService.getLocalStorage(`auth_${eventId}`);
        if (read !== null) {
            const saved = JSON.parse(read) as AuthInputResult;
            return saved;
        } else {
            const response = await WebSocketClientService.sendRequest('get:setup', { id: eventId }) as GetSetupInfoResult;
            console.log("Got auth. making modal");
            const modal = await modalController.create({
                component: NameInputModal,
                backdropDismiss: false,
                componentProps: {
                    existingNames: response.userlist,
                    auth: response.auth
                },
                keyboardClose: false
            });
            console.log("Moal made");
            await modal.present();
            console.log("Modal made");
            const result = (await modal.onWillDismiss()).data as AuthInputResult;
            console.log("Resulted", result)
            await StorageService.setLocalStorage(`auth_${eventId}`, JSON.stringify(result));
            return result;
        }
    }

    async toggleNotifications(status: boolean) {
        if (this.data.auth) {
            this.data.auth.notifications = status;
            StorageService.setLocalStorage(`auth_${this.data.id}`, JSON.stringify(this.data.auth));
            const req: NotificationToggleRequest = {
                token: status ? NotificationService.firebaseToken : undefined,
                event: this.data.id,
                user: this.data.name
            };
            await WebSocketClientService.sendRequest('notifications:toggle', req);
        }

    }

    async logout() {
        if (this.data.auth !== undefined && this.data.auth.notifications) {
            await this.toggleNotifications(false);
        }
        await StorageService.clearLocalStorage(`auth_${this.data.id}`);
        location.reload();
    }

    /**
    getName(eventId: string): Promise<string> {
        return new Promise((resolve) => {
            WebSocketClientService.sendRequest('get:userlist', { id: eventId }).then(response => {
                const userlist = response as GetUserlistResult;
                const saved = StorageService.getLocalStorage(`auth_${eventId}`);
                if (saved !== null) {
                        resolve(saved);
                } else {
                    const lang = LanguageService.getTranslator('main/name');
                    lang.add(LanguageService.getTranslator('generic'));
                    alertController.create({
                        header: lang.t('name'),
                        message: lang.t('namePrompt'),
                        inputs: [
                            {
                                placeholder: lang.t('enterName')
                            }
                        ],
                        buttons: [lang.t('continue')]
                    }).then(alert => {
                        alert.present().then(() => {
                            alert.onDidDismiss().then(result => {
                                if (userlist.data.includes(result.data.values[0])) {
                                    alertController.create({
                                        header: lang.t('doubleName'),
                                        message: lang.t('doubleNameMessage'),
                                        buttons: [
                                        {
                                            role: 'change',
                                            text: lang.t('pickDifferent')
                                        },
                                        {
                                            role: 'confirm',
                                            text: lang.t('continue')
                                        }
                                    ]
                                    }).then(alert => {
                                        alert.present().then(() => {
                                            alert.onDidDismiss().then(result2 => {
                                                if(result2.role === 'confirm') {
                                                    StorageService.setLocalStorage(`auth_${eventId}`, result.data.values[0]);
                                                    resolve(result.data.values[0]);
                                                } else {
                                                    this.getName(eventId).then(name => resolve(name));
                                                }
                                            });
                                        });
                                    })
                                } else {
                                    StorageService.setLocalStorage(`auth_${eventId}`, result.data.values[0]);
                                    resolve(result.data.values[0]);
                                }
                            })
                        });
                    });
                }
            });
        });
    }
    */
}

export default new EventService;