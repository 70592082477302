import { ModuleLanguageDefinition } from "../../LanguageService";

const def: ModuleLanguageDefinition = {
    title: [
        'Checklist',
        'Checkliste'
    ],
    desc: [
        'Create a checklist to see who is responsible for which task. You can also allow the participants to add own tasks.',
        'Erstellen sie eine Checkliste und behalten sie die Übersicht wer für welche Aufgabe zuständig ist.'
    ],
    titleTooShort: [
        'The title is too short.',
        'Der Titel ist zu kurz.'
    ],
    tooFewEntries: [
        'No entries',
        'Keine Einträge'
    ],
    titleLabel: [
        'What is the title of the checklist?',
        'Wie lautet der Titel der Checkliste?'
    ],
    newTask: [
        'New task',
        'Neue Aufgabe'
    ],
    enterTask: [
        'Enter a task...',
        'Geben sie eine Aufgabe ein...'
    ],
    tasks: [
        'Tasks',
        'Aufgaben'
    ],
    noEntries: [
        'No tasks added yet.',
        'Noch keine Aufgaben hinzugefügt.'
    ],
    needCount: [
        'Needed People',
        'Benötigte Personen'
    ],
    needed: [
        'needed',
        'benötigt'
    ],
    noVotes: [
        'No participants yet.',
        'Noch keine Verantwortlichen'
    ],
    settings: [
        'Settings',
        'Einstellungen'
    ],
    usersCanAdd: [
        'Users can add tasks',
        'Teilnehmer können Aufgaben hinzufügen'
    ],
    addTask: [
        'New task',
        'Neue Aufgabe'
    ],
    alreadyExists: [
        'Already exists',
        'Bereits vorhanden'
    ],
    deleteHeader: [
        'Delete Task',
        'Aufgabe entfernen'
    ],
    confirmDelete: [
        'Are you sure to remove this task?',
        'Möchten sie diese Aufgabe wirklich entfernen?'
    ],
    abort: [
        'Abort',
        'Abbrechen'
    ],
    confirm: [
        'Confirm',
        'Bestätigen'
    ],
    addedBy: [
        'Added by',
        'Hinzugefügt von'
    ]
}

export default def;