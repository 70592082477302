
import { defineComponent } from 'vue'

import DesignService from "../../lib/service/DesignService";

export default defineComponent({
    name: 'PhoneImage',
    props: {
        src: {
            type: String,
            default: ''
        },
        noDark: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'phone'
        }
    },
    setup() {
        return {
            DesignService
        }
    }
})
