import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Menu',
        'Menü'
    ],
    language: [
        'Language',
        'Sprache'
    ],
    createAppointa: [
        'Create Appointa',
        'Appointa erstellen'
    ],
    create: [
        'Create',
        'Erstellen'
    ],
    features: [
        'Features',
        'Funktionen'
    ],
    contact: [
        'Contact',
        'Kontakt'
    ],
    about: [
        'About',
        'Über'
    ],
    clearRecent: [
        'Clear activity',
        'Verlauf leeren'
    ],
    darkMode: [
        'Dark design',
        'Dunkles Design'
    ]
}

export default def;