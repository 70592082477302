import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b49f52e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "diagram-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_vote_diagram = _resolveComponent("vote-diagram")!
  const _component_event_module = _resolveComponent("event-module")!

  return (_openBlock(), _createBlock(_component_event_module, {
    module: "vote",
    example: _ctx.example
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          (_ctx.options.length <= 0)
            ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.lang.t('noEntries')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              key: option.title
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: ($event: any) => (_ctx.showVoterList(index, $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.person }, null, 8, ["icon"]),
                        _createVNode(_component_ion_chip, {
                          color: 
                _ctx.highestVotes > 0 && _ctx.highestVotes <= _ctx.votes[index].length
                  ? 'success'
                  : ''
              
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.showResults || _ctx.hasVoted ? _ctx.votes[index].length : "?") + " ", 1),
                            _createVNode(_component_ion_icon, { icon: _ctx.caretDown }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["color"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.title), 1)
                  ]),
                  _: 2
                }, 1024),
                (_ctx.ownsEntry(option))
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      fill: "clear",
                      color: "danger",
                      onClick: ($event: any) => (_ctx.removeEntry(index, option))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.trash }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.loading !== index)
                  ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                      key: 1,
                      onClick: ($event: any) => (_ctx.onVote(index)),
                      checked: _ctx.hasChecked(index),
                      disabled: _ctx.loading > 0 || _ctx.disabled
                    }, null, 8, ["onClick", "checked", "disabled"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.canAddOption)
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 0,
            color: "medium"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                disabled: _ctx.addLoading,
                placeholder: _ctx.lang.t('newOption'),
                modelValue: _ctx.optionInput,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.optionInput) = $event)),
                onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.addOption()), ["enter"]))
              }, null, 8, ["disabled", "placeholder", "modelValue"]),
              (_ctx.addLoading)
                ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 0 }))
                : (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addOption())),
                    disabled: !_ctx.optionInputValid,
                    class: _normalizeClass(["animated", {hidden: !_ctx.optionInputValid}])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled", "class"])),
              (_ctx.optionExists(_ctx.optionInput))
                ? (_openBlock(), _createBlock(_component_ion_badge, {
                    key: 2,
                    color: "warning"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.lang.t('alreadyExists')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        ((_ctx.showResults || _ctx.hasVoted) && _ctx.anyVotes)
          ? (_openBlock(), _createBlock(_component_vote_diagram, {
              key: 0,
              class: "embedded-diagram",
              votes: _ctx.votes,
              voteOptions: _ctx.options
            }, null, 8, ["votes", "voteOptions"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["example"]))
}