import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Contact',
        'Kontakt'
    ],
    info: [
        'Do you have a problem, a idea or just general feedback? Send us a message! We are happy to hear from you.',
        'Haben sie ein Problem oder allgemeines Feedback? Oder eine Frage? Senden sie uns eine Nachricht! Wir freuen uns von ihnen zu hören.'
    ],
    message: [
        'Message',
        'Nachricht'
    ],
    inputMessage: [
        'Input message:',
        'Nachricht eingeben:'
    ],
    wantAnswer: [
        'I would like to receive an answer',
        'Antwort erwünscht'
    ],
    mailAddress: [
        'E-Mail address',
        'E-Mail Adresse'
    ],
    enterMailAddress: [
        'Enter your mail address...',
        'Geben sie ihre E-Mail Adresse ein...'
    ],
    messageTooShort: [
        'Message too short.',
        'Nachricht zu kurz.'
    ],
    noMailAddress: [
        'E-Mail Address missing.',
        'E-Mail Adresse fehlt.'
    ],
    sending: [
        'Sending...',
        'Wird gesendet...'
    ],
    success: [
        'Success',
        'Erfolg'
    ],
    messageSent: [
        'Thanks for your message!',
        'Danke für ihre Nachricht!'
    ],
    send: [
        'Send',
        'Senden'
    ]
}

export default def;