import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00564288"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "map-view",
    onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTap()))
  }, [
    _createVNode(_component_ion_progress_bar, {
      type: "indeterminate",
      class: _normalizeClass([{'loading-hidden': !_ctx.loading}, "loading"])
    }, null, 8, ["class"]),
    (_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_ion_chip, {
          key: 0,
          class: "preview-hint"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.lang.t('preview')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("iframe", {
      src: _ctx.url,
      class: _normalizeClass(["map-frame", {'map-disabled': _ctx.disabled}]),
      onLoad: _cache[0] || (_cache[0] = ($event: any) => (this.hideLoading()))
    }, null, 42, _hoisted_1)
  ], 32))
}