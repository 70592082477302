import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    online: [
        'Online',
        'Online'
    ],
    nobody: [
        'Nobody else',
        'Niemand'
    ],
    you: [
        'You',
        'Sie'
    ]
}

export default def;