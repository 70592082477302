import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_floating_header = _resolveComponent("floating-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_floating_header, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.lang.t("recent")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        (_ctx.StorageService.data.recent.length <= 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.lang.t("noRecent")), 1))
          : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.StorageService.data.recent, (entry, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: entry.id,
                    class: "ion-activatable clickable",
                    onClick: ($event: any) => (_ctx.openEvent(entry.id))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("b", null, _toDisplayString(entry.title), 1),
                          _createElementVNode("p", null, _toDisplayString(entry.name), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_buttons, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            title: _ctx.lang.t('remove'),
                            color: "danger",
                            onClick: _withModifiers(($event: any) => (_ctx.removeEvent(index)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.removeCircleOutline }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["title", "onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ]))
}