
import { computed, defineComponent, ref } from "vue";

import {
  informationCircleOutline,
  arrowForward,
  checkmark,
  pencil,
  homeOutline,
} from "ionicons/icons";

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonCard,
  IonCardContent,
  IonLabel,
  IonItem,
  IonIcon,
  IonInput,
  IonToggle,
  modalController,
  IonRow,
} from "@ionic/vue";

import ErrorMessage from "../generic/form/ErrorMessage.vue";
import LanguageService from "../../lib/service/LanguageService";
import LanguageSelector from "../generic/LanguageSelector.vue";
import { ValidationResult } from "../../lib/form/Validation";
import PlatformService from "../../lib/service/PlatformService";

export default defineComponent({
  name: "NameInputModal",
  props: {
    existingNames: {
      type: Array,
    },
    auth: {
      type: String,
      default: "simple",
    },
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonItem,
    IonButton,
    IonLabel,
    IonIcon,
    IonInput,
    IonCard,
    IonCardContent,
    IonToggle,
    IonRow,
    ErrorMessage,
    LanguageSelector,
  },
  setup(props) {
    const lang = LanguageService.getTranslator("main/name");
    const nameInput = ref("");
    const lastNameInput = ref("");
    const exists = ref(false);
    const forceUse = ref(false);
    const notifications = ref(true);

    const name = computed(() => {
      if (props.auth === "full") {
        if (nameInput.value === "__debug__") {
          return "__debug__";
        } else {
          return `${nameInput.value.trim()} ${lastNameInput.value.trim()}`;
        }
      } else {
        return nameInput.value.trim();
      }
    });

    const validator = computed<ValidationResult>(() => {
       if (
        (props.auth === "simple" && nameInput.value.length < 2) ||
        (props.auth === "full" &&
          (nameInput.value.length < 2 || lastNameInput.value.length < 2))
      ) {
        return {
          valid: false,
          message: lang.t("nameTooShort"),
        };
      }
      return { valid: true };
    });

    const submit = () => {
       if (validator.value.valid) {
        if (
          props.existingNames !== undefined &&
          props.existingNames.includes(name.value) &&
          !forceUse.value
        ) {
          exists.value = true;
        } else {
          modalController.dismiss({
            name: name.value,
            notifications: notifications.value,
          });
        }
      }
    };

    const dismiss = () => modalController.dismiss();

    return {
      lang,
      informationCircleOutline,
      nameInput,
      lastNameInput,
      exists,
      forceUse,
      notifications,
      arrowForward,
      checkmark,
      pencil,
      homeOutline,
      name,
      validator,
      PlatformService,
      submit,
      dismiss
    };
  }
});
