
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";

import { send } from "ionicons/icons";

import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
  IonRow,
} from "@ionic/vue";

import EventModule from "../EventModule.vue";
import SpamTimerBar from "./SpamTimerBar.vue";

import EventService from "../../../lib/service/EventService";
import WebSocketClientService from "../../../net/WebSocketClientService";
import { DiscussMessageRequest } from "../../../../../shared/protocol/request/Discuss";
import LanguageService from "../../../lib/service/LanguageService";
import { DiscussMessage } from "../../../../../shared/protocol/EventData";

export default defineComponent({
  name: "DiscussModule",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    EventModule,
    SpamTimerBar,
    IonRow,
  },
  props: {
    example: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const lang = LanguageService.getTranslator("module/discuss").add(
      LanguageService.getTranslator("generic")
    );
    const messageInput = ref("");
    const loading = ref(false);
    const freeScroll = ref(false);
    const spamDelay = ref(false);
    const examples: Ref<any> = ref(undefined);
    const historyDisplay: Ref<null | HTMLDivElement> = ref(null);

    const messageValid = computed(() => {
      return messageInput.value.length >= 1 && messageInput.value.length <= 300;
    });

    const disabled = computed(() => !WebSocketClientService.status.online);

    const history = computed<DiscussMessage[]>(() => {
      if (examples.value !== undefined) {
        return examples.value[LanguageService.lang];
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.discuss !== undefined
      ) {
        return EventService.data.event.dynamic.discuss.history;
      }
      return [];
    });

    const showTime = computed(() => {
      if (props.example) {
        return true;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.discuss !== undefined
      ) {
        return EventService.data.event.static.config.discuss.showTime;
      }
      return false;
    });

    const scrollToBottom = () => {
      if (!freeScroll.value) {
        setTimeout(() => {
          if (historyDisplay.value !== null) {
            const el = historyDisplay.value;
            el.scrollTo(0, el.scrollHeight);
          }
        }, 100);
      }
    };

    const onMessageTrigger = scrollToBottom;

    const sendMessage = () => {
            if (messageValid.value && !spamDelay.value) {
        loading.value = true;
        freeScroll.value = false;
        spamDelay.value = true;
        const msgData: DiscussMessageRequest = {
          message: messageInput.value,
        };
        WebSocketClientService.sendRequest("discuss:message", msgData).then(
          () => {
            messageInput.value = "";
            setTimeout(() => {
              loading.value = false;
            }, 100);
          }
        );
        setTimeout(() => {
          spamDelay.value = false;
        }, 5000);
      }
    };

    const formatTime = (time: number) => {
      if (props.example) {
        time = (Date.now() - 600000 * time) / 60000;
      }
      return new Date(time * 60000).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const onScroll = () => {
      if(historyDisplay.value !== null) {
      const el = historyDisplay.value;
      if (el.scrollTop >= el.scrollHeight - 310) {
        freeScroll.value = false;
      } else {
        freeScroll.value = true;
      }
      }
    };

    onBeforeMount(() => {
      window.removeEventListener("discuss", onMessageTrigger);
      window.addEventListener("discuss", onMessageTrigger);
      if (props.example) {
        examples.value = require("./examples").default;
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("discuss", onMessageTrigger);
    });

    onMounted(() => {
      scrollToBottom();
    });

    return {
      lang,
      messageInput,
      loading,
      freeScroll,
      spamDelay,
      examples,
      EventService,
      send,
      messageValid,
      disabled,
      history,
      showTime,
      historyDisplay,
      scrollToBottom,
      onMessageTrigger,
      sendMessage,
      formatTime,
      onScroll
    };
  }
});
