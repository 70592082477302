import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Create Appointa',
        'Appointa erstellen'
    ],
    eventName: [
        'Event title',
        'Titel des Events'
    ],
    eventNameDesc: [
        'What is your event or meeting called like?',
        'Wie heißt dein Event oder dein Treffen?'
    ],
    enterName: [
        'Enter title...',
        'Titel eingeben...'
    ],
    authTitle: [
        'Names of the participants',
        'Namen der Teilnehmer'
    ],
    titleTooShort: [
        'Min. 2 characters',
        'Mindestens 2 Zeichen'
    ],
    titleTooLong: [
        'Max. 30 characters',
        'Maximal 30 Zeichen'
    ],
    authMode: [
        'Authentification of the participants',
        'Wie sollen sich die Teilnehmer eintragen?'
    ],
    authSimple: [
        'Simple (Just a name)',
        'Einfach (Nur ein Name)'
    ],
    authFull: [
        'Serious (First and last name)',
        'Seriös (Vor- und Nachname)'
    ]
}

export default def;