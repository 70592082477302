import LanguageService from "../service/LanguageService";

const DateFormatter = {
    weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    formatDay(timecode: number): string {
        return new Date(timecode * 60000).toUTCString();
    },
    dayOfWeek(index: number): string {
        return DateFormatter.weekdays[index];
    },
    getSystemDateFormat(ionic = false): string {
        const customDate = new Date(2222, 3, 8);
        const strDate = customDate.toLocaleDateString();
        return strDate
            .replace("04", "MM")
            .replace("4", "M")
            .replace("08", ionic ? "DD" : "dd")
            .replace("8", ionic ? "D" : "d")
            .replace("2222", ionic ? "YYYY" : "yyyy")
            .replace("22", ionic ? "YY" : "yy");
    },
    formatTime(date: Date): string {
        return date.toLocaleTimeString([], {
            hour12: !LanguageService.isMilitaryTime,
            hour: '2-digit',
            minute: '2-digit'
        });
    }
}

export default DateFormatter;