import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7498b700"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("small", {
    class: _normalizeClass({light: _ctx.light})
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.value), 1)
  ], 2))
}