import { ModuleLanguageDefinition } from "../../LanguageService";

const def: ModuleLanguageDefinition = {
    title: [
        'Discussion',
        'Diskussion'
    ],
    desc: [
        'Give the participants the opportunity to chat and discuss about the topic.',
        'Geben sie den Teilnehmern die Möglichkeit über das Thema mit einem Chat zu diskutieren.'
    ],
    titleTooShort: [
        'The title is too short.',
        'Der Titel ist zu kurz.'
    ],
    showTime: [
        'Show sending time',
        'Absendezeit anzeigen'
    ],
    titleLabel: [
        'What is the title of the discussion?',
        'Wie lautet der Titel der Diskussion?'
    ],
    enterMessage: [
        'Enter message...',
        'Nachricht eingeben...'
    ],
    limitReached: [
        'The limit of 400 messages has been reached. Older messages will be deleted.',
        'Das Limit von 400 Nachrichten wurde erreicht. Ältere Nachrichten werden gelöscht.'
    ],
    noMessages: [
        'No messages yet.',
        'Noch keine Nachrichten.'
    ]
}

export default def;