
import { defineComponent } from "vue";

import { person, caretDown, add, trash } from "ionicons/icons";

import {
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonChip,
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonSpinner,
  popoverController,
  alertController,
} from "@ionic/vue";

import EventModule from "../EventModule.vue";
import EventService from "../../../lib/service/EventService";
import WebSocketClientService from "../../../net/WebSocketClientService";
import {
  VoteAddRequest,
  VoteRemoveRequest,
  VoteRequest,
} from "../../../../../shared/protocol/request/Vote";
import VoteUserList from "./VoteUserList.vue";
import LanguageService from "../../../lib/service/LanguageService";
import VoteDiagram from "./VoteDiagram.vue";
import { VoteOption } from "../../../../../shared/protocol/Vote";

export default defineComponent({
  name: "VoteModule",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonChip,
    IonButton,
    IonButtons,
    IonIcon,
    IonInput,
    IonSpinner,
    IonCheckbox,
    EventModule,
    VoteDiagram,
  },
  data: () => ({
    lang: LanguageService.getTranslator("module/vote"),
    loading: -1,
    EventService,
    examples: undefined as any,
    optionInput: "",
    addLoading: false,
  }),
  setup() {
    return {
      person,
      caretDown,
      add,
      trash
    };
  },
  created() {
    if (this.example) {
      this.examples = require("./examples").default;
    }
  },
  props: {
    example: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canAddOption(): boolean {
      return (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.vote !== undefined &&
        EventService.data.event.static.config.vote.userAdd
      );
    },
    optionInputValid(): boolean {
      const validate = this.optionInput.trim();
      return (
        validate.length >= 2 &&
        validate.length < 30 &&
        !this.optionExists(validate)
      );
    },
    options(): VoteOption[] {
      if (this.example) {
        return this.examples[LanguageService.lang].options;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.vote !== undefined
      ) {
        return EventService.data.event.static.config.vote.options;
      }
      return [];
    },
    votes(): string[][] {
      if (this.example) {
        return this.examples[LanguageService.lang].votes;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.vote !== undefined
      ) {
        return EventService.data.event.dynamic.vote.votes;
      }
      return [];
    },
    showResults(): boolean {
      if (this.example) {
        return true;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.vote !== undefined
      ) {
        return EventService.data.event.static.config.vote.showResults;
      }
      return true;
    },
    hasVoted(): boolean {
      if (this.example) {
        return true;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.vote !== undefined
      ) {
        for (const votes of this.votes) {
          if (votes.includes(EventService.data.name)) return true;
        }
      }
      return false;
    },
    canVote(): boolean {
      return (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.vote !== undefined &&
        (EventService.data.event.static.config.vote.multipleChoice ||
          !this.hasVoted)
      );
    },
    highestVotes(): number {
      let highest = 0;
      for (const votes of this.votes) {
        if (votes.length > highest) highest = votes.length;
      }
      return highest;
    },
    anyVotes(): boolean {
      if (this.example) return true;
      for (const votes of this.votes) {
        if (votes.length > 0) return true;
      }
      return false;
    },
    disabled() {
      return !WebSocketClientService.status.online;
    },
  },
  methods: {
    onVote(index: number) {
      this.loading = index;
      const data: VoteRequest = {
        index,
      };
      WebSocketClientService.sendRequest("vote:vote", data).then(() => {
        this.loading = -1;
      });
    },
    ownsEntry(item: VoteOption): boolean {
      return EventService.data.event !== undefined && item.user !== undefined && item.user === EventService.data.name;
    },
    async removeEntry(index: number, item: VoteOption) {
      const alert = await alertController.create({
        header: this.lang.t('deleteHeader'),
        subHeader: item.title,
        message: this.lang.t('confirmDelete'),
        buttons: [
          {
            role: 'cancel',
            text: this.lang.t('cancel')
          },
          {
            role: 'destructive',
            text: this.lang.t('confirm')
          }
        ]
      });
      await alert.present();
      const result = await alert.onDidDismiss();
      if(result.role === 'destructive') {
        const req: VoteRemoveRequest = {
          index
        };
        await WebSocketClientService.sendRequest('vote:remove', req);
      }
    },
    addOption() {
      if (this.optionInputValid && !this.addLoading) {
        this.addLoading = true;
        const data: VoteAddRequest = {
          title: this.optionInput,
        };
        WebSocketClientService.sendRequest("vote:add", data).then(() => {
          this.addLoading = false;
          this.optionInput = '';
        });
      }
    },
    optionExists(title: string): boolean {
      title = title.toLowerCase().trim();
      for (const option of this.options as VoteOption[]) {
        if (option.title.toLowerCase() === title) return true;
      }
      return false;
    },
    hasChecked(index: number): boolean {
      if (this.example) {
        return Math.random() > 0.5;
      }
      return (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.vote !== undefined &&
        EventService.data.event.dynamic.vote.votes[index].includes(
          EventService.data.name
        )
      );
    },
    async showVoterList(index: number, event: Event) {
      if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.vote !== undefined &&
        EventService.data.event.dynamic.vote !== undefined
      ) {
        if (
          EventService.data.event.static.config.vote.showResults ||
          this.hasVoted
        ) {
          console.log("Showing", EventService.data.event.static.config.vote.options[index])
          const popover = await popoverController.create({
            event,
            component: VoteUserList,
            componentProps: {
              title:
                EventService.data.event.static.config.vote.options[index].title,
              users: EventService.data.event.dynamic.vote.votes[index],
              creator: EventService.data.event.static.config.vote.options[index].user
            },
          });
          await popover.present();
        } else {
          const alert = await alertController.create({
            header: this.lang.t("results"),
            message: this.lang.t("voteFirst"),
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  },
});
