
import { computed, defineComponent, reactive } from "vue";

import { sendOutline } from "ionicons/icons";

import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonCheckbox,
  IonCardHeader,
  IonCardTitle,
  IonTextarea,
  IonRow,
  IonIcon,
  IonButton,
  loadingController,
  alertController,
  modalController,
} from "@ionic/vue";

import LanguageService from "../../../lib/service/LanguageService";

import ModalHeader from "./ModalHeader.vue";
import ErrorMessage from "../form/ErrorMessage.vue";
import { ValidationResult } from "../../../lib/form/Validation";
import WebSocketClientService from "../../../net/WebSocketClientService";

export default defineComponent({
  name: "ContactModal",
  components: {
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
    IonInput,
    IonContent,
    IonCheckbox,
    IonTextarea,
    IonCardHeader,
    IonCardTitle,
    IonRow,
    IonButton,
    IonIcon,
    ErrorMessage,
    ModalHeader,
  },
  setup() {
    const lang = LanguageService.getTranslator("modal/contact");
    const data = reactive({
      message: "",
      wantAnswer: false,
      contact: "",
    });

    const validator = computed<ValidationResult>(() => {
      if (data.message.length < 1) {
        return { valid: false };
      } else if (data.message.length <= 20) {
        return { valid: false, message: "messageTooShort" };
      } else if (data.wantAnswer && data.contact.length <= 5) {
        return { valid: false, message: "noMailAddress" };
      }
      return { valid: true };
    });

    const send = async () => {
      const loader = await loadingController.create({
        message: lang.t("sending"),
        duration: 5000,
      });
      await loader.present();
      await WebSocketClientService.sendRequest("contact", data);
      await loader.dismiss();
      const alert = await alertController.create({
        header: lang.t("success"),
        message: lang.t("messageSent"),
        buttons: ["OK"],
      });
      await alert.present();
      await modalController.dismiss();
    };

    return {
      lang,
      data,
      validator,
      sendOutline,
      send
    };
  },
});
