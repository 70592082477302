import { LanguageDefinition } from "../LanguageService";

const def: LanguageDefinition = {
    menuCreate: [
        'Create Appointa',
        'Appointa erstellen'
    ],
    menuMoreApps: [
        'More apps',
        'Mehr Apps'
    ],
    language: [
        'Language',
        'Sprache'
    ],
    letsGo: [
        'Let`s go',
        'Los geht`s'
    ],
    nothingHereYet: [
        'Nothing too see yet.',
        'Hier ist noch nichts.'
    ],
    createHint: [
        'If you create a new Appointa or participate in someone elses it will appear here.',
        'Abstimmungen die du erstellst oder an denen du teilnimmst werden hier aufgeführt.'
    ],
    tryNow: [
        'Try now',
        'Sofort loslegen'
    ]
}

export default def;