import { Capacitor } from "@capacitor/core";
import { reactive } from "vue";
import StorageService from "./StorageService";

class PlatformService {
    limits = {
        medium: 800,
        large: 1200
    };

    data = reactive({
        platform: Capacitor.getPlatform(),
        large: window.innerWidth >= this.limits.large,
        medium: window.innerWidth >= this.limits.medium,
        notifications: Capacitor.getPlatform() !== 'web'
    });

    constructor() {
        window.matchMedia(`(min-width: ${this.limits.medium}px)`).addEventListener('change', (value) => {
            this.data.medium = value.matches;
        });
        window.matchMedia(`(min-width: ${this.limits.large}px)`).addEventListener('change', (value) => {
            this.data.large = value.matches;
        });
    }

    async getShowIntro(): Promise<boolean> {
        const storage = await StorageService.getLocalStorage('intro', 'false');
        return storage === 'false' && !this.data.medium;
    }
}

export default new PlatformService;