import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c133debe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swapping-module" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "module" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_module = _resolveComponent("date-module")!
  const _component_checklist_module = _resolveComponent("checklist-module")!
  const _component_discuss_module = _resolveComponent("discuss-module")!
  const _component_vote_module = _resolveComponent("vote-module")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", { src: _ctx.img }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.widget === 'date')
        ? (_openBlock(), _createBlock(_component_date_module, {
            key: 0,
            example: true
          }))
        : _createCommentVNode("", true),
      (_ctx.widget === 'checklist')
        ? (_openBlock(), _createBlock(_component_checklist_module, {
            key: 1,
            example: true
          }))
        : _createCommentVNode("", true),
      (_ctx.widget === 'discuss')
        ? (_openBlock(), _createBlock(_component_discuss_module, {
            key: 2,
            example: true
          }))
        : _createCommentVNode("", true),
      (_ctx.widget === 'vote')
        ? (_openBlock(), _createBlock(_component_vote_module, {
            key: 3,
            example: true
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}