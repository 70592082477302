
import { defineComponent } from 'vue'
import {IonProgressBar} from "@ionic/vue";

export default defineComponent({
    name: 'SpamTimerBar',
    components: {
        IonProgressBar
    }
})
