import { ChecklistEntry } from "../../../../../shared/protocol/Checklist";

interface ChecklistExamples {
    entries: ChecklistEntry[];
    votes: string[][];
}
const examples: {[key in 'de' | 'en']: ChecklistExamples} = {
    de: {
        entries: [
            {
                title: 'Essen kochen',
                need: 3
            },
            {
                title: 'Aufräumen',
                need: 4
            },
            {
                title: 'Raum mieten',
                need: 1
            }
        ],
        votes: [
            ['Max', 'Erika', 'Heinz'],
            ['Susanne', 'Peter'],
            ['Klaus']
        ]
    },
    en: {
        entries: [
            {
                title: 'Cooking food',
                need: 3
            },
            {
                title: 'Cleaning',
                need: 4
            },
            {
                title: 'Rent the location',
                need: 1
            }
        ],
        votes: [
            ['John', 'Jane', 'Peter'],
            ['Susan', 'Charles'],
            ['Donald']
        ]
    }
}

export default examples;