import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    home: [
        'Home',
        'Start'
    ],
    language: [
        'Language',
        'Sprache'
    ],
    logout: [
        'Logout',
        'Abmelden'
    ],
    imprint: [
        'Imprint',
        'Impressum'
    ],
    darkMode: [
        'Dark design',
        'Dunkles Design'
    ],
    notifications: [
        'Notifications',
        'Benachrichtigungen'
    ]
}

export default def;