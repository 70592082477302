
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import LanguageService from "../../../lib/service/LanguageService";
import DesignService from "../../../lib/service/DesignService";

export default defineComponent({
  name: "AnimatedTitle",
  setup() {
    const data = {
      de: {
        type: ["Treffen", "Meetings", "Konferenzen", "Veranstaltungen"],
        how: ["einfach", "nachhaltig", "schnell"],
      },
      en: {
        type: ["meetings", "events", "conferences"],
        how: ["easy", "sustainable", "fast"],
      },
    };
    const animated = ref(false);
    const how = ref(0);
    const type = ref(0);
    let advanceTask: NodeJS.Timer | undefined;

    const german = computed(() => {
      return LanguageService.lang === "de";
    });

    const howText = computed(() => {
      return data[LanguageService.lang].how[how.value];
    });

    const typeText = computed(() => {
      return data[LanguageService.lang].type[type.value];
    });

    const advance = () => {
      setTimeout(() => {
        if (type.value < data[LanguageService.lang].type.length - 1) {
          type.value++;
        } else {
          type.value = 0;
        }
        if (how.value < data[LanguageService.lang].how.length - 1) {
          how.value++;
        } else {
          how.value = 0;
        }
      }, 500);
      animated.value = true;
      setTimeout(() => {
        animated.value = false;
      }, 500);
    };

    onMounted(() => {
      advanceTask = setInterval(() => advance(), 4000);
    });
    onUnmounted(() => {
      if (advanceTask !== undefined) {
        clearInterval(advanceTask);
      }
    });
    return {
      type,
      how,
      animated,
      DesignService,
      data,
      german,
      typeText,
      howText,
    };
  },
});
