
import { defineComponent } from 'vue'
import LanguageService from '../../../lib/service/LanguageService';

import ModalHeader from "./ModalHeader.vue";

export default defineComponent({
    name: 'ImprintModal',
    components: {
        ModalHeader
    },
    setup() {
        const lang = LanguageService.getTranslator('modal/imprint');
        return {
            lang
        }
    }
})
