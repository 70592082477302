import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Imprint',
        'Impressum'
    ],
    createdBy: [
        'Created and maintained by',
        'Entwicklung und Betrieb'
    ]
}

export default def;