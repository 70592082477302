import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    offline: [
        'Offline',
        'Offline'
    ],
    presentedBy: [
        'Presented with',
        'Präsentiert mit'
    ]
}

export default def;