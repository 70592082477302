import EventService from "@/lib/service/EventService";
import ClientAdapter from "../ClientAdapter";

export class ListAdatper extends ClientAdapter {
    actions = ['list'];

    on(action: string, data: any) {
        switch(action) {
            case 'list':
                EventService.data.online = data;
                break;
        }
    }
}