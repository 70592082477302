import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    recent: [
        'Recently participated',
        'Zuletzt teilgenommen'
    ],
    noRecent: [
        'Here you will see a list of the last votings that you participated in.',
        'Hier werden sie eine Liste der letzten Abstimmungen sehen an denen sie teilgenommen haben.'
    ],
    open: [
        'Open',
        'Öffnen'
    ],
    remove: [
        'Remove from list',
        'Aus der Liste entfernen'
    ]
}

export default def;