
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";

import {
  person,
  checkmark,
  close,
  chevronForward,
  help,
  checkmarkCircle,
  closeCircle,
  helpCircle,
} from "ionicons/icons";

import {
  IonIcon,
  IonButtons,
  IonButton,
  createGesture,
  Gesture,
} from "@ionic/vue";

import { DynamicDateData } from "../../../../../shared/protocol/EventData";
import LanguageService from "../../../lib/service/LanguageService";
import WebSocketClientService from "../../../net/WebSocketClientService";
import { DateVoteRequest } from "../../../../../shared/protocol/request/Date";

export default defineComponent({
  name: "DateTable",
  components: {
    IonIcon,
    IonButtons,
    IonButton,
  },
  props: {
    dates: {
      type: Array,
      required: true,
    },
    dynamic: {
      type: Object,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dateTable: Ref<null | HTMLDivElement> = ref(null);
    const lang = LanguageService.getTranslator("module/date");
    const exceedsScreen = ref(false);
    const scrollEnd = ref(false);
    const dragStart = ref(-1);
    let observer: undefined | ResizeObserver = undefined;
    let gesture: undefined | Gesture = undefined;

    const vote = (index: number, vote: "y" | "n" | "m") => {
      const req: DateVoteRequest = {
        index,
        vote,
      };
      WebSocketClientService.sendRequest("date:vote", req, true);
    };

    const scrollStep = () => {
      if(dateTable.value !== null) {
      const element = dateTable.value;
      const bounds = element.getBoundingClientRect();
      element.scrollBy(bounds.width * 0.9, 0);
      }
    };

    const onScroll = () => {
      if(dateTable.value !== null) {
      const element = dateTable.value;
      const bounds = element.getBoundingClientRect();
      scrollEnd.value = element.scrollLeft + bounds.width >= element.scrollWidth;
      }
    };

    const voted = (index: number, name: string, vote: "y" | "n" | "m") => {
      return props.dynamic.votes[index][vote].includes(name);
    };

    const voteScore = (index: number) => {
      return (
        props.dynamic.votes[index].y.length + props.dynamic.votes[index].m.length
      );
    };

    const weightedVoteScore = (index: number) => {
      return (
        props.dynamic.votes[index].y.length +
        props.dynamic.votes[index].m.length * 0.5
      );
    };

    const yesCount = (index: number) => {
      return props.dynamic.votes[index].y.length;
    };

    const maybeCount = (index: number) => {
      return props.dynamic.votes[index].m.length;
    };

    const voterList = computed<string[]>(() => {
      let voters: string[] = [];
      for (const date of (props.dynamic as DynamicDateData).votes) {
        voters = voters.concat(
          date.y.filter((el) => !voters.includes(el) && el !== props.username)
        );
        voters = voters.concat(
          date.n.filter((el) => !voters.includes(el) && el !== props.username)
        );
        voters = voters.concat(
          date.m.filter((el) => !voters.includes(el) && el !== props.username)
        );
      }
      return voters;
    });

    const bestScore = computed(() => {
      let best = -1;
      for (let i = 0; i < props.dates.length; i++) {
        const score = weightedVoteScore(i);
        if (score > 0 && score > best) best = score;
      }
      return best;
    });

    onMounted(() => {
      if (dateTable.value !== null) {
        observer = new ResizeObserver(() => {
          if (dateTable.value !== null) {
            exceedsScreen.value =
              dateTable.value.scrollWidth > window.innerWidth;
          }
        });
        observer.observe(dateTable.value);
        gesture = createGesture({
          el: dateTable.value,
          threshold: 10,
          gestureName: "tableScroll",
          blurOnStart: true,
          onStart: () => {
            if (dateTable.value !== null) {
              dragStart.value = dateTable.value.scrollLeft;
            }
          },
          onMove: (event) => {
            requestAnimationFrame(() => {
              if (dragStart.value >= 0 && dateTable.value !== null) {
                dateTable.value.scrollLeft = -event.deltaX + dragStart.value;
              }
            });
          },
          onEnd: () => {
            dragStart.value = -1;
          },
        });
        gesture.enable();
      }
    });

    onBeforeUnmount(() => {
      gesture?.destroy();
      observer?.disconnect();
    });

    return {
      lang,
      dateTable,
      person,
      checkmark,
      chevronForward,
      close,
      help,
      exceedsScreen,
      scrollEnd,
      dragStart,
      checkmarkCircle,
      closeCircle,
      helpCircle,
      voterList,
      bestScore,
      vote,
      scrollStep,
      onScroll,
      voted,
      voteScore,
      weightedVoteScore,
      yesCount,
      maybeCount
    };
  },
  methods: {

  },
});
