import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'How to start',
        'So funktioniert`s'
    ],
    setupTitle: [
        'Create an event',
        'Erstelle ein neues Event'
    ],
    setupText: [
        'Click "Create" to begin. Enter the title and select which features you want to use.',
        'Klicken sie auf "Erstellen" um zu starten. Wählen sie einen Titel und welche Funktionen sie benötigen.'
    ],
    shareTitle: [
        'Share it',
        'Teilen'
    ],
    shareText: [
        'Send the link you received to the participants.',
        'Senden sie den generierten Link an die Teilnehmer.'
    ],
    waitTitle: [
        'Wait',
        'Warten sie ab'
    ],
    waitText: [
        'Wait a bit while the participants take part in your votings. Watch the results in real time.',
        'Warten sie ab, während die Teilnehmer abstimmen. Die Ergebnisse können in Echtzeit verfolgt werden.'
    ],
    resultsTitle: [
        'Results',
        'Ergebnisse'
    ],
    resultsText: [
        'See the final results, like the perfect time for the meeting or the preferred food, and tell the participants.',
        'Sehen sie die Ergebnisse, zum Beispiel die perfekte Zeit zum Treffen oder das beliebteste Essen, und teilen sie dies den Teilnehmern mit.'
    ]
}

export default def;