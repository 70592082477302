
import { defineComponent } from "vue";

import {
  IonHeader,
  IonTitle,
  IonButtons,
  IonButton,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
  name: "ModalHeader",
  props: {
    title: {
      type: String,
    },
  },
  components: {
    IonHeader,
    IonTitle,
    IonButtons,
    IonButton,
    IonToolbar,
  },
  setup() {
    const lang = LanguageService.getTranslator('generic');

    const dismiss = () => modalController.dismiss();

    return {
      lang,
      dismiss
    }
  }
});
