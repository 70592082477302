
import { defineComponent } from "vue";

import {
  peopleOutline,
  flashOutline,
  desktopOutline,
  leafOutline,
} from "ionicons/icons";

import { IonCard, IonItem, IonIcon, IonLabel, IonRow } from "@ionic/vue";
import LanguageService from "../../../lib/service/LanguageService";
import FloatingHeader from "../splash/FloatingHeader.vue";
import ContentFilter from "../../generic/ContentFilter.vue";

export default defineComponent({
  name: "FeatureSnippets",
  components: {
    IonCard,
    IonItem,
    IonIcon,
    IonLabel,
    IonRow,
    FloatingHeader,
    ContentFilter,
  },
  setup() {
    return {
      lang: LanguageService.getTranslator("home/feature_snippets"),
      items: [
        {
          key: "individual",
          icon: peopleOutline,
        },
        {
          key: "live",
          icon: flashOutline,
        },
        {
          key: "platforms",
          icon: desktopOutline,
        },
        {
          key: "sustainable",
          icon: leafOutline,
        },
      ],
    };
  },
});
