import { ModuleLanguageDefinition } from "../../LanguageService";

const def: ModuleLanguageDefinition = {
    title: [
        'Information text',
        'Infotext'
    ],
    desc: [
        'Enter a text to give the participants further information.',
        'Geben sie einen Text ein um den Teilnehmern weitere Informationen zu bereitzustellen.'
    ],
    enterText: [
        'Describe your meeting...',
        'Beschreiben sie ihre Veranstaltung...'
    ],
    tooShort: [
        'Text has to have at least 5 characters.',
        'Der Text muss mindestens 5 Zeichen lang sein.'
    ],
    titleTooShort: [
        'The title is too short.',
        'Der Titel ist zu kurz.'
    ],
    titleLabel: [
        'What is the title of the text?',
        'Wie lautet der Titel des Textes?'
    ]
}

export default def;