import { LanguageDefinition } from "../../LanguageService";

export default {
    discard: [
        'Discard',
        'Verwerfen'
    ],
    abort: [
        'Abort',
        'Abbrechen'
    ],
    confirmDiscard: [
        'Discard input?',
        'Eingaben verwerfen?'
    ]
} as LanguageDefinition;