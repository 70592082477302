import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Done!',
        'Fertig!'
    ],
    createLoading: [
        'Creating your Appointa...',
        'Dein Appointa wird erstellt...'
    ],
    ready: [
        'Your Appointa is ready!',
        'Dein Appointa ist bereit!'
    ],
    openEvent: [
        'Open my Appointa',
        'Mein Appointa öffnen'
    ]
}

export default def;