import { DateInfo } from "../../../../../shared/protocol/Date";
import { DynamicDateData } from "../../../../../shared/protocol/EventData";


interface DateVoteExample {
    dates: DateInfo[];
    dynamic: DynamicDateData;
    user: string;
}

const examples: { [key in 'en' | 'de']: DateVoteExample } = {
    de:
    {
        dates: [
            {
                weekday: 0,
                time: '20:30',
                formatted: '10.12.2040',
                timestamp: 0
            },
            {
                weekday: 2,
                time: '19:40',
                formatted: '12.12.2040',
                timestamp: 0
            },
            {
                weekday: 6,
                time: '12:15',
                formatted: '16.12.2040',
                timestamp: 0
            }
        ],
        dynamic: {
            votes: [
                { y: ['Max'], n: ['Sandra'], m: ['Peter', 'Paula'] },
                { y: ['Max', 'Peter', 'Paula'], n: [], m: ['Sandra'] },
                { y: ['Sandra'], n: ['Peter', 'Paula'], m: ['Max'] }
            ]
        },
        user: 'Max'
    },
    en: {
        dates: [
            {
                weekday: 0,
                time: '08:30 pm',
                formatted: '10/10/2040',
                timestamp: 0
            },
            {
                weekday: 2,
                time: '7:30 am',
                formatted: '10/12/2040',
                timestamp: 0
            },
            {
                weekday: 6,
                time: '1:15 pm',
                formatted: '12/16/2040',
                timestamp: 0
            }
        ],
        dynamic: {
            votes: [
                { y: ['John'], n: ['Jane'], m: ['Peter', 'Emma'] },
                { y: ['John', 'Peter'], n: ['Emma'], m: ['Jane'] },
                { y: ['Jane'], n: ['Peter', 'Emma'], m: ['John'] }
            ]
        },
        user: 'John'
    }
}

export default examples;