import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-034f3d37"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "lazy-content",
  ref: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["dynamic-lazy-content", { hidden: !_ctx.visible }])
    }, [
      (_ctx.visible)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
        : _createCommentVNode("", true)
    ], 2)
  ], 512))
}