import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import EventService from './EventService';
import PlatformService from './PlatformService';


class NotificationService {
  public firebaseToken?: string;

  init() {
    if (PlatformService.data.notifications) {
            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
            (token: Token) => {
              this.firebaseToken = token.value;
              console.log('Push registration success, token: ' + token.value);
            }
          );
    
          // Some issue with our setup and push will not work
          PushNotifications.addListener('registrationError',
            (error: any) => {
              console.log('Error on push registration: ' + JSON.stringify(error));
            }
          );
    
          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              console.log('Push received: ' + JSON.stringify(notification));
            }
          );
    
          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
              if (notification.notification.data.event !== undefined) {
                EventService.join(notification.notification.data.event);
              }
              console.log('Push action performed: ' + JSON.stringify(notification));
            }
          );

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    }
  }
}

export default new NotificationService;