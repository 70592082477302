import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    connecting: [
        'Connecting...',
        'Verbinde...'
    ],
    reconnected: [
        'Reconnected to server.',
        'Verbindung zum Server hergestellt.'
    ]
}

export default def;