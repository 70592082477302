
import { defineComponent } from "vue";

import { openOutline, removeCircleOutline } from "ionicons/icons";

import {
  IonCard,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonButtons,
  IonButton,
} from "@ionic/vue";
import LanguageService from "../../lib/service/LanguageService";
import StorageService from "../../lib/service/StorageService";
import FloatingHeader from "./splash/FloatingHeader.vue";
import EventService from "../../lib/service/EventService";

export default defineComponent({
  name: "RecentView",
  components: {
    IonCard,
    IonList,
    IonIcon,
    IonLabel,
    IonItem,
    IonButtons,
    IonButton,
    FloatingHeader,
  },
  setup() {
    const openEvent = (id: string) => EventService.join(id);
    const removeEvent = (index: number) => StorageService.removeRecent(index);

    return {
      lang: LanguageService.getTranslator("home/recent"),
      openOutline,
      removeCircleOutline,
      openEvent,
      removeEvent,
      StorageService
    };
  }
});
