
import { defineComponent, onMounted, Ref, ref } from "vue";

export default defineComponent({
  name: "LazyContent",
  props: {
    fade: {
      type: Boolean,
      default: true,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const visible = ref(false);
    const content: Ref<null | HTMLElement> = ref(null);

    onMounted(() => {
      if (content.value !== null) {
        const observer = new IntersectionObserver(
          (ev) => {
            if (props.remove) {
              visible.value = ev[0].isIntersecting;
            } else if (ev[0].isIntersecting && !visible.value) {
              visible.value = true;
              observer.disconnect();
            }
          },
          {
            threshold: 0.75,
          }
        );
        observer.observe(content.value);
      }
    });
    
    return {
      visible,
      content,
    };
  },
});
