import { reactive } from "vue";
import { Storage } from '@capacitor/storage';

export interface RecentInfo {
    title: string;
    id: string;
    name: string;
    time: number;
}

class StorageService {

    data = reactive({
        recent: [] as RecentInfo[]
    });

    constructor() {
        this.loadRecent();
    }

    async getLocalStorage(key: string, defaultValue: string | null = null): Promise<string | null> {
        const item = await Storage.get({ key });
        if (item.value !== null) {
            return item.value;
        } else {
            return defaultValue;
        }
    }

    async setLocalStorage(key: string, value: string) {
        Storage.set({
            key,
            value
        });
    }

    async existsLocalStorage(key: string): Promise<boolean> {
        const result = await Storage.get({
            key
        });
        return result.value !== null;
    }

    async clearLocalStorage(key: string) {
        await Storage.remove({ key });
    }

    init() {
        this.loadRecent();
    }

    async loadRecent() {
        const read = await this.getLocalStorage('recent');
        if (read !== null) {
            const readData = (JSON.parse(read) as RecentInfo[]);
            const oldLimit = 60 * 24 * 365;
            const old = readData.filter(el => (Date.now() / 60000) - el.time > oldLimit);
            if (old.length > 0) {
                console.log("Clearing old events from recent:", old.length);
                this.data.recent = readData.filter(el => !old.includes(el));
                await this.setLocalStorage('recent', JSON.stringify(this.data.recent));
            } else {
                this.data.recent = readData;
            }
        } else {
            return [];
        }
    }

    async addRecent(info: RecentInfo) {
        const current = this.data.recent;
        const existing = current.find(element => element.id === info.id);
        if (existing) {
            current.splice(current.indexOf(existing), 1);
        }
        current.push(info);
        if (current.length > 5) {
            current.splice(0, 1);
        }
        this.data.recent = current;
        await this.setLocalStorage('recent', JSON.stringify(current));
    }

    async removeRecent(index: number) {
        this.data.recent.splice(index, 1);
        await this.setLocalStorage('recent', JSON.stringify(this.data.recent));
    }
}

export default new StorageService;