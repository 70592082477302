<template>
    <div>
        <h3><slot></slot></h3>
        <div class="underline" />
    </div>
</template>

<style lang="scss" scoped>
.underline {
    height: 2px;
    width: 10em;
    background-color: gray;
    margin-left: auto;
    margin-right: auto;
}
h3 {
    text-align: center;
}
</style>