import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from "../components/home/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/privacy',
    component: () => import('../components/generic/modals/Privacy.vue')
  },
  {
    path: '/example',
    component: () => import('../components/modules/ScreenshotExample.vue')
  },
  {
    path: '/screenshot-phone',
    component: () => import('../components/screenshot/PhoneScreenshotContainer.vue')
  },
  {
    path: '/create',
    redirect: '/create/title',
    component: () => import('../components/create/Create.vue'),
    children: [
      {
        path: 'title',
        component: () => import('../components/create/TitleInput.vue')
      },
      {
        path: 'modules',
        component: () => import('../components/create/ModuleSelection.vue')
      },
      {
        path: 'setup',
        component: () => import('../components/create/setup/Setup.vue'),
        children: [
          {
            path: 'date',
            component: () => import('../components/create/setup/SetupDate.vue')
          },
          {
            path: 'description',
            component: () => import('../components/create/setup/SetupDescription.vue')
          },
          {
            path: 'discuss',
            component: () => import('../components/create/setup/SetupDiscuss.vue')
          },
          {
            path: 'vote',
            component: () => import('../components/create/setup/SetupVote.vue')
          },
          {
            path: 'map',
            component: () => import('../components/create/setup/SetupMap.vue')
          },
          {
            path: 'checklist',
            component: () => import('../components/create/setup/SetupChecklist.vue')
          }
        ]
      },
      {
        path: 'done',
        component: () => import('../components/create/CreateDone.vue')
      }
    ]
  },
  {
    path: '/a',
    component: () => import('../components/main/MainView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
