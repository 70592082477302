
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonIcon,
  IonTitle,
  IonPage,
  IonContent,
  menuController,
  modalController,
} from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import SplashBanner from "./SplashBanner.vue";
import HomeMenu from "./HomeMenu.vue";
import ContentFilter from "../generic/ContentFilter.vue";
import { add } from "ionicons/icons";
import LanguageService from "../../lib/service/LanguageService";
import Features from "./features/Features.vue";
import HomeFooter from "./footer/HomeFooter.vue";
import LazyContent from "../generic/LazyContent.vue";
import FeatureSnippets from "./about/FeatureSnippets.vue";
import ContactModal from "../generic/modals/ContactModal.vue";
import StorageService from "../../lib/service/StorageService";
import IntroModal from "./intro/IntroModal.vue";
import PlatformService from "../../lib/service/PlatformService";
import { SplashScreen } from "@capacitor/splash-screen";

export default defineComponent({
  name: "Home",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonIcon,
    IonPage,
    SplashBanner,
    IonContent,
    HomeMenu,
    ContentFilter,
    Features,
    HomeFooter,
    IonTitle,
    LazyContent,
    FeatureSnippets,
  },
  setup() {
    const showIntro = async () => {
      const modal = await modalController.create({
        component: IntroModal,
        backdropDismiss: false,
      });
      await modal.present();
      await modal.onDidDismiss();
      StorageService.setLocalStorage("intro", "true");
    };

    const openMenu = () => menuController.open("home");
    const showContact = async () => {
      const modal = await modalController.create({
        component: ContactModal,
      });
      await modal.present();
    };

    PlatformService.getShowIntro().then((value) => {
      if (value) {
        showIntro();
      }
    });

    onMounted(() => {
      PlatformService.getShowIntro().then((value) => {
        if (value) {
          setTimeout(() => {
            SplashScreen.hide();
          }, 300);
        }
      });
    });

    return {
      lang: LanguageService.getTranslator("home/menu"),
      PlatformService,
      add,
      showContact,
      openMenu,
    };
  },
});
