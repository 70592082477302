
import { defineComponent } from 'vue'

import EventModule from "../EventModule.vue";
import MapView from "./MapView.vue";

import EventService from "../../../lib/service/EventService";

export default defineComponent({
    name: 'MapModule',
    components: {
        EventModule,
        MapView
    },
    props: {
        example: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        EventService
    }),
    computed: {
        location(): string {
            if(this.example) {
                return 'New York'
            } else if(EventService.data.event !== undefined && EventService.data.event.static.config.map !== undefined) {
                return EventService.data.event.static.config.map.location;
            }
            return '';
        },
        zoom(): number {
            if(this.example) {
                return 16;
            } else if(EventService.data.event !== undefined && EventService.data.event.static.config.map !== undefined) {
                return EventService.data.event.static.config.map.zoom;
            }
            return 10;
        }
    }
})
