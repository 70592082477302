import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    name: [
        'Name',
        'Name'
    ],
    namePrompt: [
        'Your name',
        'Ihr Name'
    ],
    enterName: [
        'Enter name...',
        'Namen eingeben...'
    ],
    firstName: [
        'First name',
        'Vorname'
    ],
    lastName: [
        'Last name',
        'Nachname'
    ],
    enterFirstName: [
        'Enter first name...',
        'Vornamen eingeben...'
    ],
    enterLastName: [
        'Enter last name...',
        'Nachnamen eingeben...'
    ],
    nameExists: [
        'This name has been already used. If you still participate with this name, you will possible overirde existing data. Continue?',
        'Dieser Name wird bereits verwendet. Wenn sie fortfahren könnten sie vorhandene Eingaben für diesen Namen überschreiben. Möchten sie dennoch fortfahren?'
    ],
    useName: [
        'Use name',
        'Namen verwenden'
    ],
    changeName: [
        'Change name',
        'Namen ändern'
    ],
    doubleNameMessage: [
        'This name is already used. If you use this name you will possibly override data.',
        'Dieser Name wurde bereits genutzt. Sie werden möglicherweise vorhandene Eingaben überschreiben.'
    ],
    pickDifferent: [
        'Change name',
        'Namen ändern'
    ],
    nameDescription: [
        'With this name you can interact in voting and discussions.',
        'Mit diesem Namen nehmen sie an Abstimmungen und Diskussionen teil.'
    ],
    go: [
        'Go',
        'Los'
    ],
    nameTooShort: [
        'Name too short',
        'Der Name ist zu kurz'
    ],
    notifications: [
        'Receive notifications',
        'Benachrichtigungen erhalten'
    ],
    notificationsHint: [
        'Use the Appointa App to recieve notifications.',
        'Nutze die Appointa App um Benachrichtigungen zu erhalten.'
    ]
}

export default def;