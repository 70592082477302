
import { defineComponent, onBeforeUnmount, onMounted, Ref } from "vue";

import {
  addCircleOutline,
  trash,
  mailOutline,
  close,
  languageOutline,
  contrastOutline,
} from "ionicons/icons";

import {
  IonMenu,
  IonList,
  IonTitle,
  IonHeader,
  IonItem,
  IonLabel,
  IonToolbar,
  IonIcon,
  IonContent,
  IonToggle,
  IonButtons,
  IonButton,
  menuController,
  modalController,
} from "@ionic/vue";

import LanguageSelector from "../generic/LanguageSelector.vue";
import LanguageService from "../../lib/service/LanguageService";
import StorageService from "../../lib/service/StorageService";
import DesignService from "../../lib/service/DesignService";
import PlatformService from "../../lib/service/PlatformService";
import ContactModal from "../generic/modals/ContactModal.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HomeMenu",
  components: {
    IonMenu,
    IonList,
    IonItem,
    IonLabel,
    IonTitle,
    IonHeader,
    IonIcon,
    IonToolbar,
    IonContent,
    IonToggle,
    IonButtons,
    IonButton,
    LanguageSelector,
  },
  setup() {
    let observer: undefined | IntersectionObserver;
    const visible = ref(false);
    const bigLogo: Ref<null | HTMLElement> = ref(null);
    const router = useRouter();

    const create = () => {
      menuController.close();
      router.push("/create");
    };

    const toggleDarkMode = () => {
      DesignService.setDarkMode(!DesignService.design.dark);
    };

    const dismiss = () => modalController.dismiss();

    const showContact = async () => {
      const modal = await modalController.create({
        component: ContactModal,
      });
      await modal.present();
    };

    onMounted(() => {
      observer = new IntersectionObserver(
        (ev) => {
          visible.value = ev[0].isIntersecting;
        },
        {
          threshold: 0.9,
        }
      );
      if (bigLogo.value !== null) {
        observer.observe(bigLogo.value);
      }
    });

    onBeforeUnmount(() => {
      if (observer !== undefined) {
        observer.disconnect();
      }
    });

    return {
      lang: LanguageService.getTranslator("home/menu"),
      visible,
      observer,
      addCircleOutline,
      trash,
      mailOutline,
      close,
      languageOutline,
      contrastOutline,
      DesignService,
      PlatformService,
      StorageService,
      bigLogo,
      create,
      dismiss,
      toggleDarkMode,
      showContact
    };
  },
});
