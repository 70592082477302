import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_map_view = _resolveComponent("map-view")!
  const _component_event_module = _resolveComponent("event-module")!

  return (_openBlock(), _createBlock(_component_event_module, {
    module: "map",
    example: _ctx.example
  }, {
    default: _withCtx(() => [
      _createVNode(_component_map_view, {
        query: _ctx.location,
        zoom: _ctx.zoom
      }, null, 8, ["query", "zoom"])
    ]),
    _: 1
  }, 8, ["example"]))
}