import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { class: "ion-padding" }, {
    default: _withCtx(() => [
      (_ctx.votes.y.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ion_list_header, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.lang.t("yes")), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votes.y, (voter, i) => {
              return (_openBlock(), _createBlock(_component_ion_item, { key: i }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(voter), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.checkmark,
                    color: "success"
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.votes.m.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_ion_list_header, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.lang.t("maybe")), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votes.m, (voter, i) => {
              return (_openBlock(), _createBlock(_component_ion_item, { key: i }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(voter), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.help,
                    color: "tertiary"
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.votes.n.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_ion_list_header, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.lang.t("no")), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votes.n, (voter, i) => {
              return (_openBlock(), _createBlock(_component_ion_item, { key: i }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(voter), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.close,
                    color: "danger"
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.noVotes)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _createTextVNode(_toDisplayString(_ctx.lang.t("noVotes")), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}