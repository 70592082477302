import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    imprint: [
        'Imprint',
        'Impressum'
    ],
    contact: [
        'Contact',
        'Kontakt'
    ]
}

export default def;