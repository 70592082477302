
import { defineComponent } from "vue";

import {
  logoIonic,
  logoVue,
  logoCapacitor,
  logoNpm,
  logoSass,
  logoNodejs,
} from "ionicons/icons";

import { IonIcon, modalController } from "@ionic/vue";

import LanguageService from "../../../lib/service/LanguageService";
import ImprintModal from "../../generic/modals/ImprintModal.vue";
import ContactModal from "../../generic/modals/ContactModal.vue";
import ContentFilter from "../../generic/ContentFilter.vue";

export default defineComponent({
  name: "HomeFooter",
  components: {
    IonIcon,
    ContentFilter,
  },
  setup() {
    const lang = LanguageService.getTranslator("home/footer");

    const showImprint = async () => {
      const modal = await modalController.create({
        component: ImprintModal,
      });
      await modal.present();
    };

    const showContact = async () => {
      const modal = await modalController.create({
        component: ContactModal,
      });
      await modal.present();
    };

    return {
      lang,
      logoIonic,
      logoVue,
      logoCapacitor,
      logoNpm,
      logoSass,
      logoNodejs,
      showContact,
      showImprint
    };
  },
});
