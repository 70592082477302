import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.LanguageService.list, (lang) => {
        return (_openBlock(), _createBlock(_component_ion_img, {
          key: lang,
          src: `assets/language/${lang}.svg`,
          class: _normalizeClass(["flag", { 'flag-selected': _ctx.LanguageService.selected === lang }]),
          onClick: ($event: any) => (_ctx.select(lang))
        }, null, 8, ["src", "class", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}