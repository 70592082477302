
import { computed, defineComponent } from "vue";

import { close } from "ionicons/icons";

import {
  IonList,
  IonListHeader,
  IonItem,
  IonText,
  IonLabel,
  IonProgressBar,
  IonIcon,
} from "@ionic/vue";
import EventService from "../../../lib/service/EventService";
import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
  name: "ChecklistUserList",
  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonText,
    IonProgressBar,
    IonIcon,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    users: {
      required: true,
      type: Array,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const lang = LanguageService.getTranslator("module/checklist");

    const hasProgress = computed(() => {
      return (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.checklist !== undefined &&
        EventService.data.event.static.config.checklist.entries[props.index]
          .need > 1
      );
    });

    const progressValue = computed(() => {
      if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.checklist !== undefined &&
        EventService.data.event.dynamic.checklist !== undefined
      ) {
        return (
          EventService.data.event.dynamic.checklist.votes[props.index].length /
          EventService.data.event.static.config.checklist.entries[props.index]
            .need
        );
      } else {
        return 0;
      }
    });

    const isDone = computed(() => {
      return (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.checklist !== undefined &&
        EventService.data.event.dynamic.checklist !== undefined &&
        EventService.data.event.static.config.checklist.entries[props.index]
          .need ===
          EventService.data.event.dynamic.checklist.votes[props.index].length
      );
    });

    const addedBy = computed(() => {
      if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.checklist !== undefined
      ) {
        return EventService.data.event.static.config.checklist.entries[
          props.index
        ].user;
      } else {
        return undefined;
      }
    });

    return {
      lang,
      EventService,
      close,
      hasProgress,
      progressValue,
      isDone,
      addedBy
    };
  },
});
