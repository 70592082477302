import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    controllerOffline: [
        'The server system is currently offline. We are working on it.',
        'Das Serversystem ist derzeit nicht erreichbar. Wir arbeiten daran.'
    ],
    error: [
        'Error',
        'Fehler'
    ],
    serverConnection: [
        'Server connection',
        'Serververbindung'
    ],
    tryAgain: [
        'Try again',
        'Erneut versuchen'
    ],
    lostConnection: [
        'Lost connection to server.',
        'Verbindung zum Server verloren.'
    ],
    noConnection: [
        'No connection could be established. Check your network connection.',
        'Es konnte keine Verbindung hergestellt werden. Prüfen sie ihre Netzwerkverbindung.'
    ]
}

export default def;