
import { defineComponent } from 'vue'

import {IonPage, IonContent, modalController} from "@ionic/vue";
import Intro from "./Intro.vue";

export default defineComponent({
    name: 'IntroModal',
    components: {
        IonPage,
        IonContent,
        Intro
    },
    setup() {
        return {
            onEnd: () => modalController.dismiss()
        }
    }
})
