import { PublicServerInstanceConfiguration } from "../../../server/src/config";
import HTTPClientService from "./HTTPClientService";
import { RequestMessage, AnswerMessage, Message, BroadcastMessage } from "../../../shared/protocol/Communication";
import ClientAdapter from "./ClientAdapter";
import ConnectionAdapter from "./adapter/ConnectionAdapter";
import { alertController } from "@ionic/vue";
import LanguageService, { Translator } from "@/lib/service/LanguageService";
import EditAdapter from "./adapter/EditAdapter";
import CreateService from "@/lib/service/CreateService";
import EventService from "@/lib/service/EventService";
import TriggerAdapter from "./adapter/TriggerAdapter";
import { ListAdatper } from "./adapter/ListAdapter";
import { Capacitor } from "@capacitor/core";
import { reactive } from "vue";

class WebSocketClientService {

    private socket?: WebSocket;
    private instance?: PublicServerInstanceConfiguration;
    private adapters: { [key: string]: ClientAdapter } = {};
    private lang: Translator;
    private messageId = 0;
    private responseListeners: { [key: string]: any } = {};
    private readyPromise: Promise<void>;
    private readyResolve: any;

    public status = reactive({ online: false, loading: false });
    public firstConnected = false;


    constructor() {
        this.lang = LanguageService.getTranslator('net/error');
        this.lang = this.lang.add(LanguageService.getTranslator('net/generic'));
        this.addAdapter(new ConnectionAdapter());
        this.addAdapter(new EditAdapter());
        this.addAdapter(new TriggerAdapter());
        this.addAdapter(new ListAdatper());
        this.readyPromise = new Promise((resolve) => {
            this.readyResolve = resolve;
        });
    }

    getUrl(): string {
        return `${location.protocol.includes('https') || Capacitor.getPlatform() !== 'web' ? 'wss' : 'ws'}://${this.instance ? this.instance.public : ''}`;
    }

    establish() {
        HTTPClientService.configLoaded.then(() => {
            this.instance = HTTPClientService.getRandomInstance();
            if (this.instance) {
                CreateService.info.server = this.instance.id;
            }
            if (this.instance !== undefined) {
                this.connect().then(() => {
                    this.readyResolve();
                });
            }
        });
    }

    connect(): Promise<void> {
        return new Promise((resolve) => {
            try {
                this.socket = new WebSocket(this.getUrl());
                this.socket.addEventListener('message', (msgData) => {
                    try {
                        const rawMessage = JSON.parse(msgData.data) as Message;
                        if (rawMessage.type === 'request') {
                            const message = rawMessage as RequestMessage;
                            if (this.adapters[message.action] !== undefined) {
                                this.adapters[message.action].on(message.action, message.data);
                            } else {
                                console.warn('No adapter found for action', message.action);
                            }
                        } else if (rawMessage.type === 'broadcast') {
                            const message = rawMessage as BroadcastMessage;
                            if (this.adapters[message.action] !== undefined) {
                                this.adapters[message.action].on(message.action, message.data);
                            } else {
                                console.warn('No adapter found for broadcast action', message.action);
                            }
                        } else if (rawMessage.type === 'answer') {
                            this.status.loading = false;
                            const message = rawMessage as AnswerMessage;
                            if (this.responseListeners[message.id] !== undefined) {
                                this.responseListeners[message.id](message.data);
                            } else {
                                console.warn('Response without listener:', message);
                            }
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                });

                this.socket.addEventListener('close', () => {
                    if (this.status.online) {
                        /**
                        toastController.create({
                            header: this.lang.t('error'),
                            message: this.lang.t('lostConnection'),
                            duration: 5000
                        }).then(toast => {
                            toast.present();
                        });
                        */
                       console.log("Socket close");
                        this.status.online = false;
                    }
                    setTimeout(() => { this.connect() }, location.hostname === 'localhost' ? 1000 : 5000);
                });
                this.socket.addEventListener('error', err => {
                    console.log("Socket err", err);
                });
                this.socket.addEventListener('open', () => {
                    console.log("Socket open");
                    this.status.online = true;
                    if (this.firstConnected) {
                        /*
                        toastController.create({
                            message: this.lang.t('reconnected'),
                            duration: 3000
                        }).then(toast => {
                            toast.present();
                        });
                        */
                        if (EventService.data.event !== undefined) {
                            EventService.reload();
                        }
                    } else {
                        this.firstConnected = true;
                        resolve();
                    }
                });
            } catch (ex) {
                alertController.create({
                    header: this.lang.t('error'),
                    message: this.lang.t('noConnection'),
                    buttons: [{ role: 'ok', text: 'OK' }, { role: 'tryAgain', text: this.lang.t('tryAgain') }]
                }).then((alert: { present: () => Promise<any>; onDidDismiss: () => Promise<any> }) => {
                    alert.present().then(() => {
                        alert.onDidDismiss().then(result => {
                            if (result.role === 'tryAgain') {
                                this.connect().then(() => {
                                    resolve();
                                });
                            }
                        });
                    });
                });
            }
        });
    }

    addAdapter(adapter: ClientAdapter) {
        for (const action of adapter.actions) {
            if (this.adapters[action] !== undefined) {
                console.warn('WebSocket Adapter override for action', action);
            }
            this.adapters[action] = adapter;
        }
    }

    sendRequest(action: string, data: any, isVoid = false): Promise<any> {
        return new Promise((resolve, reject) => {
            if(!isVoid) this.status.loading = true;
            if (!this.firstConnected) {
                this.establish();
            }
            this.readyPromise.then(() => {
                if (this.status.online) {
                    if (this.socket) {
                        const msg: RequestMessage = {
                            type: 'request',
                            action,
                            data,
                            id: isVoid ? undefined : this.messageId++,
                        };
                        if (msg.id !== undefined) {
                            this.responseListeners[msg.id] = resolve;
                        }
                        this.socket.send(JSON.stringify(msg));
                    } else {
                        console.error('Server is undefined');
                        reject();
                    }
                } else {
                    alertController.create({
                        header: this.lang.t('error'),
                        message: this.lang.t('noConnection'),
                        buttons: ['OK']
                    }).then((alert: { present: () => void }) => { alert.present() });
                    return resolve(undefined);
                }
            });
        })
    }
}

export default new WebSocketClientService;