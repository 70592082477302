
import { defineComponent } from "vue";

import { create, shareSocial, peopleOutline, listOutline } from "ionicons/icons";

import { IonCard, IonItem, IonIcon, IonLabel } from "@ionic/vue";

import FloatingHeader from "../splash/FloatingHeader.vue";
import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
  name: "HowTo",
  components: {
    IonCard,
    IonItem,
    IonIcon,
    IonLabel,
    FloatingHeader,
  },
  setup() {
    return {
      lang: LanguageService.getTranslator("home/howto"),
      items: [
        {
          key: "setup",
          icon: create
        },
        {
            key: "share",
            icon: shareSocial
        },
        {
            key: "wait",
            icon: peopleOutline
        },
        {
            key: "results",
            icon: listOutline
        }
      ],
    };
  },
});
