import { LanguageDefinition } from "../../LanguageService";


const def: LanguageDefinition = {
    description: [
        'Description',
        'Beschreibung'
    ],
    enterDescription: [
        'Enter a description...',
        'Geben sie eine Beschreibung ein...'
    ],
    addSubtitle: [
        'Description',
        'Beschreibung'
    ]
}

export default def;