import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62414cde"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "home-section",
  id: "features"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_floating_header = _resolveComponent("floating-header")!
  const _component_date_module = _resolveComponent("date-module")!
  const _component_feature_entry = _resolveComponent("feature-entry")!
  const _component_checklist_module = _resolveComponent("checklist-module")!
  const _component_discuss_module = _resolveComponent("discuss-module")!
  const _component_vote_module = _resolveComponent("vote-module")!
  const _component_map_module = _resolveComponent("map-module")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_floating_header, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.lang.t("title")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_feature_entry, {
      title: _ctx.lang.t('date'),
      subtitle: _ctx.lang.t('dateText')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_date_module, { example: true })
      ]),
      _: 1
    }, 8, ["title", "subtitle"]),
    _createVNode(_component_feature_entry, {
      title: _ctx.lang.t('checklist'),
      subtitle: _ctx.lang.t('checklistText'),
      left: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_checklist_module, { example: true })
      ]),
      _: 1
    }, 8, ["title", "subtitle"]),
    _createVNode(_component_feature_entry, {
      title: _ctx.lang.t('discuss'),
      subtitle: _ctx.lang.t('discussText')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_discuss_module, { example: true })
      ]),
      _: 1
    }, 8, ["title", "subtitle"]),
    _createVNode(_component_feature_entry, {
      title: _ctx.lang.t('vote'),
      subtitle: _ctx.lang.t('voteText'),
      left: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_vote_module, { example: true })
      ]),
      _: 1
    }, 8, ["title", "subtitle"]),
    _createVNode(_component_feature_entry, {
      title: _ctx.lang.t('map'),
      subtitle: _ctx.lang.t('mapText')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_map_module, { example: true })
      ]),
      _: 1
    }, 8, ["title", "subtitle"])
  ]))
}