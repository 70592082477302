
import { defineComponent } from 'vue'

import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle } from "@ionic/vue";
import EventService from '../../lib/service/EventService';
import LanguageService, { Translator } from '../../lib/service/LanguageService';

export default defineComponent({
    name: 'EventModule',
    components: {
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle
    },
    props: {
        module: {
            type: String,
            required: true
        },
        example: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        lang: undefined as (undefined | Translator)
    }),
    created() {
        this.lang = this.example ? LanguageService.getTranslator(`module/${this.module}`) : undefined;
    },
    computed: {
        title(): string {
            if(EventService.data.event !== undefined && (EventService.data.event.static.config as any)[this.module] !== undefined) {
                return (EventService.data.event.static.config as any)[this.module].title;
            } else {
                return this.lang ? this.lang.t('title') : '';
            }
        },
        subtitle(): string | undefined {
            if(EventService.data.event !== undefined && (EventService.data.event.static.config as any)[this.module] !== undefined) {
                return (EventService.data.event.static.config as any)[this.module].subtitle;
            } else {
                return this.lang ? this.lang.t('desc') : '';
            }
        }
    }
})
