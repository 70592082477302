import ClientAdapter from "../ClientAdapter";
import { TriggerRequest } from "../../../../shared/protocol/request/Trigger";

export default class TriggerAdapter extends ClientAdapter {
    actions = ['trigger'];

    on(action: string, data: object) {
        switch (action) {
            case 'trigger':
                {
                    const req = (data as TriggerRequest);
                    window.dispatchEvent(new CustomEvent(req.trigger, {
                        detail: req.data
                    }));
                }
                break;
        }
    }
}