
import { defineComponent } from "vue";
import { IonImg, IonRow } from "@ionic/vue";
import LanguageService, { Language } from "../../lib/service/LanguageService";

export default defineComponent({
  components: {
    IonImg,
    IonRow,
  },
  setup() {
    const select = (lang: Language) => {
      LanguageService.setLanguage(lang);
      location.reload();
    };
    return {
      LanguageService,
      select
    };
  }
});
