import ClientAdapter from "../ClientAdapter";
import WebSocketClientService from "../WebSocketClientService";
import {ConnectData} from "../../../../shared/protocol/Communication";

export default class ConnectionAdapter extends ClientAdapter {
    actions = ['connect'];

    on(action: string, data: any) {
        switch(action) {
            case 'connect':
                WebSocketClientService.status.online = (data as ConnectData).state;
                if(!WebSocketClientService.firstConnected) {
                    WebSocketClientService.firstConnected = true;
                }
                break;
        }
    }
}