import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Features',
        'Funktionen'
    ],
    date: [
        'Date voting',
        'Terminabstimmung'
    ],
    dateText: [
        'Find the perfect date for meeting.',
        'Den perfekten Zeitpunkt zum Treffen finden.'
    ],
    vote: [
        'Voting',
        'Abstimmung'
    ],
    voteText: [
        'Opinions and Feedback',
        'Meinungen und Feedback.'
    ],
    checklist: [
        'Checklist',
        'Checkliste'
    ],
    checklistText: [
        'Divide the responsibilities.',
        'Aufgabenverteilung und Fortschrittsanzeige.'
    ],
    map: [
        'Map',
        'Karte'
    ],
    mapText: [
        'Route and Location.',
        'Anfahrt und Standort.'
    ],
    discuss: [
        'Discussion',
        'Diskussion'
    ],
    discussText: [
        'Chat and exchange.',
        'Chat und Austausch.'
    ]
}

export default def;