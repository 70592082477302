import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.show)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true),
    (!_ctx.show)
      ? _renderSlot(_ctx.$slots, "not", { key: 1 })
      : _createCommentVNode("", true)
  ]))
}