import { DiscussMessage } from "../../../../../shared/protocol/EventData";

const examples: {[key in 'de' | 'en']: DiscussMessage[]} = {
    de: [
        {
            m: 'Ich freu mich schon!',
            s: 'Susanne',
            t: 2
        },
        {
            m: 'Ich auch.',
            s: 'Max',
            t: 1
        },
        {
            m: 'Gibt es eine Alternative bei schlechtem Wetter?',
            s: 'Sandra',
            t: 0
        }
    ],
    en: [
        {
            m: 'Looking forward to meet you!',
            s: 'Jane',
            t: 2
        },
        {
            m: 'Me too.',
            s: 'John',
            t: 1
        },
        {
            m: 'Is there an altenative plan for bad weather?',
            s: 'Peter',
            t: 0
        }
    ]
}

export default examples;