import { LanguageDefinition } from "../LanguageService";

const def: LanguageDefinition = {
    continue: [
        'Continue',
        'Fortfahren'
    ],
    add: [
        'Add',
        'Hinzufügen'
    ],
    done: [
        'Done',
        'Fertig'
    ],
    title: [
        'Title',
        'Titel'
    ],
    settings: [
        'Settings',
        'Einstellungen'
    ],
    enterTitle: [
        'Enter title...',
        'Titel eingeben...'
    ],
    oclock: [
        '',
        'Uhr'
    ],
    error: [
        'Error',
        'Fehler'
    ],
    close: [
        'Close',
        'Schließen'
    ]
}

export default def;
