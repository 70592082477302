import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_error_message = _resolveComponent("error-message")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, {
        title: _ctx.lang.t('title')
      }, null, 8, ["title"]),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.lang.t("info")), 1),
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.lang.t("message")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_textarea, {
                    maxlength: "1000",
                    rows: "6",
                    modelValue: _ctx.data.message,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.message) = $event)),
                    placeholder: _ctx.lang.t('inputMessage')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.lang.t("wantAnswer")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_checkbox, {
                    modelValue: _ctx.data.wantAnswer,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.wantAnswer) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.data.wantAnswer)
        ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.lang.t("mailAddress")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.data.contact,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.contact) = $event)),
                        placeholder: _ctx.lang.t('enterMailAddress')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_row, { class: "ion-justify-content-end ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_button, {
              disabled: !_ctx.validator.valid,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.send()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.lang.t("send")) + " ", 1),
                _createVNode(_component_ion_icon, {
                  slot: "end",
                  icon: _ctx.sendOutline
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_error_message, {
              value: _ctx.lang.t(_ctx.validator.message)
            }, null, 8, ["value"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}