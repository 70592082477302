
import { defineComponent, onBeforeUnmount, onMounted, Ref } from "vue";

import { arrowForward } from "ionicons/icons";

import {
  IonicSwiper,
  IonFooter,
  IonToolbar,
  IonButton,
  IonContent,
  IonIcon,
} from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "@ionic/vue/css/ionic-swiper.css";
import SwappingWidget from "./SwappingWidget.vue";

import LanguageService, {
  Translator,
} from "../../../lib/service/LanguageService";
import PlatformService from "../../../lib/service/PlatformService";
import { ref } from "vue";

SwiperCore.use([IonicSwiper, Navigation, Pagination]);

interface FeatureListEntry {
  key: string;
  lang: Translator;
  img: any;
}

export default defineComponent({
  name: "Intro",
  components: {
    Swiper,
    SwiperSlide,
    IonFooter,
    IonToolbar,
    IonButton,
    IonContent,
    IonIcon,
    SwappingWidget,
  },
  emits: ['end'],
  setup(props, {emit}) {
    const finalSlide: Ref<null | HTMLElement> = ref(null);
    const lang =
      LanguageService.getTranslator("home/features").add("home/intro");
    let observer: undefined | IntersectionObserver = undefined;
    const finalSlideVisible = ref(false);


    const done = () => {
      emit("end");
    };

    onMounted(() => {
      observer = new IntersectionObserver(
        (event) => {
          finalSlideVisible.value = event[0].isIntersecting;
          if (finalSlideVisible.value) {
            setTimeout(() => {
              done();
            }, 1000);
          }
        },
        {
          threshold: 1,
        }
      );
      if (observer !== undefined && finalSlide.value !== null) {
        observer.observe(finalSlide.value);
      }
    });

    onBeforeUnmount(() => {
      if (observer !== undefined && finalSlide.value !== null) {
        observer.disconnect();
      }
    });

    return {
      arrowForward,
      lang,
      finalSlideVisible: ref(false),
      finalSlide,
      swiper: ref(null),
      observer,
      done,
      PlatformService,
      list: [
        {
          key: "date",
          lang: LanguageService.getTranslator("module/date"),
          img: require("../../../assets/art/time_management.webp"),
        },
        {
          key: "checklist",
          lang: LanguageService.getTranslator("module/checklist"),
          img: require("../../../assets/art/checklist.webp"),
        },
        {
          key: "vote",
          lang: LanguageService.getTranslator("module/vote"),
          img: require("../../../assets/art/voting.webp"),
        },
        {
          key: "discuss",
          lang: LanguageService.getTranslator("module/discuss"),
          img: require("../../../assets/art/discussion.webp"),
        },
      ] as Array<FeatureListEntry>,
    };
  }
});
