
import { computed, defineComponent } from "vue";

import PlatformService from "../../lib/service/PlatformService";

export default defineComponent({
  name: "ContentFilter",
  props: {
    web: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const show = computed(() => {
      if (props.web) {
        if (PlatformService.data.platform === "web") {
          if (props.large) {
            return PlatformService.data.large;
          } else if (props.medium) {
            return PlatformService.data.medium;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else if (props.mobile) {
        if (PlatformService.data.platform !== "web") {
          if (props.small) {
            return !PlatformService.data.medium;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        if (props.small) {
          return !PlatformService.data.medium;
        } else if (props.medium) {
          return PlatformService.data.medium;
        } else if (props.large) {
          return PlatformService.data.large;
        }
      }
      return true;
    });
    
    return {
        show
    }
  },
});
