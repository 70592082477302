
import { defineComponent } from "vue";

import { IonList, IonListHeader, IonItem, IonLabel, IonText } from "@ionic/vue";
import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonListHeader,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    creator: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    lang: LanguageService.getTranslator("module/vote"),
  }),
});
