
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  VueElement,
} from "vue";
import LanguageService from "../../lib/service/LanguageService";
import { playOutline, add, arrowForward } from "ionicons/icons";

import { IonButton, IonIcon, IonRow, IonCol } from "@ionic/vue";
import ContentFilter from "../generic/ContentFilter.vue";
import AnimatedTitle from "./splash/AnimatedTitle.vue";
import RecentView from "./RecentView.vue";
import HowTo from "./howto/HowTo.vue";
import DesignService from "../../lib/service/DesignService";
import PhoneImage from "../generic/PhoneImage.vue";
import StorageService from "../../lib/service/StorageService";

export default defineComponent({
  name: "SplashBanner",
  components: {
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    ContentFilter,
    AnimatedTitle,
    RecentView,
    HowTo,
    PhoneImage,
  },
  setup() {
    const lang = LanguageService.getTranslator("home").add(
      LanguageService.getTranslator("home/menu")
    );
    const headlineState = ref(0);
    const phoneVisible = ref(false);
    const firstVisit = ref(false);
    const visible = ref(false);
    let observer: undefined | IntersectionObserver = undefined;
    let phoneObserver: undefined | IntersectionObserver = undefined;
    const content: Ref<null | HTMLElement> = ref(null);
    const phoneImage: Ref<null | VueElement> = ref(null);

    const headline = computed(() => {
      return lang.t(`hl_${headlineState.value}`);
    });

    const hasRecent = computed(() => {
      return StorageService.data.recent.length > 0;
    });

    const checkFirstVisit = () => {
      if (localStorage.getItem("visited_before")) {
        return false;
      } else {
        localStorage.setItem("visited_before", "true");
        return true;
      }
    };

    onMounted(() => {
      if (content.value !== null) {
        observer = new IntersectionObserver((ev) => {
          visible.value = ev[0].isIntersecting;
        });
        observer.observe(content.value);

        firstVisit.value = checkFirstVisit();

        if (phoneImage.value !== null) {
          phoneObserver = new IntersectionObserver((ev) => {
            phoneVisible.value = ev[0].isIntersecting;
          });
          phoneObserver.observe((phoneImage.value as any).$el);
        }
      }
    });

    onUnmounted(() => {
      if (observer !== undefined) {
        observer.disconnect();
      }
      if (phoneObserver !== undefined) {
        phoneObserver.disconnect();
      }
    });

    return {
      lang,
      headlineState,
      headline,
      hasRecent,
      add,
      playOutline,
      arrowForward,
      DesignService,
      LanguageService,
      phoneVisible,
      firstVisit,
      visible,
      content,
      phoneImage,
    };
  }
});
