
import { defineComponent } from "vue";

import DateModule from "../../modules/date/DateModule.vue";
import ChecklistModule from "../../modules/checklist/ChecklistModule.vue";
import DiscussModule from "../../modules/discuss/DiscussModule.vue";
import VoteModule from "../../modules/vote/VoteModule.vue";

export default defineComponent({
  name: "SwappingWidget",
  props: {
    widget: {
      type: String,
      required: true,
    },
    img: {
      required: true,
    },
  },
  components: {
    DateModule,
    ChecklistModule,
    DiscussModule,
    VoteModule,
  }
});
