import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    skip: [
        'Skip intro',
        'Intro überspringen'
    ],
    welcome: [
        'Welcome',
        'Wilkommen'
    ],
    welcomeText: [
        'Welcome to Appointa. Swipe left to get a quick overview over some of the possibilities.',
        'Wilkommen bei Appointa. Wische nach links um einen kleinen Überblick über einige der Möglichkeiten zu bekommen.'
    ]
};
export default def;