
import { computed, defineComponent } from "vue";

import { PieChart, usePieChart } from "vue-chart-3";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import { VoteOption } from "../../../../../shared/protocol/Vote";
Chart.register(...registerables);

export default defineComponent({
  components: {
    PieChart,
  },
  props: {
    voteOptions: {
      type: Array,
      required: true
    },
    votes: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const data = computed<ChartData<"pie">>(() => ({
      labels: props.voteOptions.map(
        (option) => (option as VoteOption).title
      ),
      datasets: [
        {
          data: props.votes.map((e) => (e as string).length),
          backgroundColor: [
            "#a6cee3",
            "#1f78b4",
            "#b2df8a",
            "#33a02c",
            "#fb9a99",
            "#e31a1c",
            "#fdbf6f",
            "#ff7f00",
            "#cab2d6",
            "#6a3d9a",
            "#ffff99",
            "#b15928",
          ],
        },
      ],
    }));

    const options = computed<ChartOptions<"doughnut">>(() => ({
      maintainAspectRatio: true,
      responsive: true
    }));

    const { pieChartProps, pieChartRef } = usePieChart({
      chartData: data,
      options: {
        responsive: false,
        maintainAspectRatio: false
      }
    });

    return {
      data,
      options,
      pieChartProps,
      pieChartRef,
    };
  },
});
