import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_event_module = _resolveComponent("event-module")!

  return (_openBlock(), _createBlock(_component_event_module, {
    module: "checklist",
    example: _ctx.example
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          (_ctx.entries.length <= 0)
            ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.lang.t("noEntries")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (item, index) => {
            return (_openBlock(), _createBlock(_component_ion_item, { key: item }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { disabled: _ctx.disabled }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_chip, {
                      onClick: _withModifiers(($event: any) => (_ctx.showList(index, $event)), ["stop"]),
                      color: _ctx.isDone(index) ? 'success' : ''
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isDone(index))
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.checkmark
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(_ctx.votes[index].length) + " ", 1),
                        (_ctx.entries[index].need > 1)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(" / " + _toDisplayString(_ctx.entries[index].need), 1)
                            ], 64))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ion_icon, { icon: _ctx.caretDown }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["onClick", "color"])
                  ]),
                  _: 2
                }, 1032, ["disabled"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1024),
                (_ctx.ownsEntry(item))
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      fill: "clear",
                      color: "danger",
                      onClick: ($event: any) => (_ctx.removeEntry(index, item))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.trash }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_checkbox, {
                  checked: _ctx.hasChecked(index),
                  onClick: _withModifiers(($event: any) => (_ctx.check(index)), ["stop"])
                }, null, 8, ["checked", "onClick"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.userAddEnabled)
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 0,
            color: "medium"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                disabled: _ctx.addLoading,
                placeholder: _ctx.lang.t('addTask'),
                modelValue: _ctx.taskInput,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.taskInput) = $event)),
                onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.addEntry()), ["enter"]))
              }, null, 8, ["disabled", "placeholder", "modelValue"]),
              (_ctx.addLoading)
                ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 0 }))
                : (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    disabled: !_ctx.taskInputValid,
                    class: _normalizeClass(["animated", { hidden: !_ctx.taskInputValid }]),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addEntry()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled", "class"])),
              (_ctx.taskExists(_ctx.taskInput))
                ? (_openBlock(), _createBlock(_component_ion_badge, {
                    key: 2,
                    color: "warning"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.lang.t("alreadyExists")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.taskInput.length >= 2)
        ? (_openBlock(), _createBlock(_component_ion_item, {
            key: 1,
            color: "medium"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.lang.t("needCount")) + ": ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                type: "number",
                min: "1",
                max: "100",
                onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.addEntry()), ["enter"])),
                modelValue: _ctx.needInput,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.needInput) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["example"]))
}