import { reactive } from 'vue';
import DateFormatter from '../formatting/DateFormatter';
import StorageService from './StorageService';

export type Language = 'en' | 'de';
export interface LanguageDefinition {
    [key: string]: Array<string>;
}
export interface ModuleLanguageDefinition extends LanguageDefinition {
    title: Array<string>;
    desc: Array<string>;
}

export class Translator {
    private languageService: LanguageService;
    public definition: LanguageDefinition;

    constructor(languageService: LanguageService, definition: LanguageDefinition) {
        this.languageService = languageService;
        this.definition = definition;
    }

    t(key: string): string {
        if (!key) return '';
        if (this.definition[key]) {
            return this.definition[key][this.languageService.langIndex];
        } else {
            console.error('Invalid language key:', key);
            return key;
        }
    }

    add(translator: Translator | string): Translator {
        if (translator instanceof Translator) {
            return new Translator(this.languageService, Object.assign({}, this.definition, translator.definition));
        } else {
            return this.add(this.languageService.getTranslator(translator));
        }
    }
}

interface TranslatorCache {
    [key: string]: Translator;
}

class LanguageService {
    lang: Language = 'en';
    langIndex = 0;
    list: Array<Language> = ['en', 'de'];
    private cache: TranslatorCache = {};
    status = reactive({
        init: false
    });
    isMilitaryTime = true;
    timeFormat = '';
    dateFormat = '';
    ionicDateFormat = '';

    constructor() {
        this.determineDefaultLanguage().then(language => {
            this.setLanguage(language).then(() => {
                const date = new Date();
                const timeString = date.toLocaleTimeString();
                this.isMilitaryTime = !(timeString.match(/am|pm/i) || date.toString().match(/am|pm/i));
                if (this.isMilitaryTime) {
                    this.timeFormat = 'HH:mm';
                } else {
                    this.timeFormat = 'hh:mm a';
                }
                this.dateFormat = DateFormatter.getSystemDateFormat();
                this.ionicDateFormat = DateFormatter.getSystemDateFormat(true);
                this.status.init = true;
            });
        });
    }

    async setLanguage(language: Language) {
        this.lang = language;
        this.langIndex = this.getLanguageIndex(language);
        await StorageService.setLocalStorage('lang', language);
    }

    public getTranslator(scope: string): Translator {
        if (this.cache[scope] !== undefined) {
            return this.cache[scope];
        } else {
            const def = require(`./language/${scope}`).default as LanguageDefinition;
            const translator = new Translator(this, def);
            this.cache[scope] = translator;
            return translator;
        }
    }

    private async determineDefaultLanguage(): Promise<Language> {
        const stored = await StorageService.getLocalStorage('lang');
        if (stored !== null) {
            if (stored === 'de' || stored === 'en') {
                return stored as Language;
            } else {
                console.warn('Invalid language in local storage:', stored, '. Using fallback.');
                return 'en';
            }
        } else {
            return navigator.language.includes('de') ? 'de' : 'en';
        }
    }

    private getLanguageIndex(lang: Language): number {
        return this.list.indexOf(lang);
    }
}

export default new LanguageService;