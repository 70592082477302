import ClientAdapter from "../ClientAdapter";
import EventService from "@/lib/service/EventService";
import { ModuleChangeResult, MultiModuleChangeResult } from "../../../../server/src/db/Manager";

export default class EditAdapter extends ClientAdapter {
    actions = ['edit', 'edit:multi', 'push'];

    on(action: string, data: any) {
        switch (action) {
            case 'edit':
                {
                    const changes = data as ModuleChangeResult;
                    if (EventService.data.event) {
                        if (changes.static) {
                            if(changes.path) {
                                (EventService.data.event.static.config as any)[changes.module][changes.path] = changes.data;
                            } else {
                                (EventService.data.event.static.config as any)[changes.module] = changes.data;
                            }
                        } else {
                            if (changes.path) {
                                (EventService.data.event.dynamic as any)[changes.module][changes.path] = changes.data;
                            } else {
                                (EventService.data.event.dynamic as any)[changes.module] = changes.data;
                            }
                        }
                    }
                }
                break;
            case 'edit:multi':
                {
                    const changes = data as MultiModuleChangeResult;
                    if(EventService.data.event) {
                        for(const change of changes.changes) {
                            this.on('edit', change);
                        }
                    }
                }
                break;
            case 'push':
                {
                    const changes = data as ModuleChangeResult;
                    if (EventService.data.event) {
                        if (changes.path) {
                            (EventService.data.event.dynamic as any)[changes.module][changes.path].push(changes.data);
                        } else {
                            (EventService.data.event.dynamic as any)[changes.module].push(changes.data);
                        }
                    }
                }
                break;
        }
    }
}