import { LanguageDefinition } from "../../LanguageService";


const def: LanguageDefinition = {
    title: [
        'Why use Appointa?',
        'Warum Appointa?'
    ],
    individualTitle: [
        'Individual use',
        'Individueller Einsatz'
    ],
    individualText: [
        'Appointa can be used for every use case of planning a meeting - from a little dinner with friends to a buiseness meeting.',
        'Appointa kann auf alle Planungsfälle zugeschnitten werden - vom Abendessen mit Freunden bis zum geschäftlichen Meeting.'
    ],
    liveTitle: [
        'Real time',
        'Ergebnisse in Echtzeit'
    ],
    liveText: [
        'Watch the results of the votings live and interact with the participants in real time.',
        'Beobachten sie die Ergebnisse und interagieren sie mit den Teilnehmern in Echtzeit.'
    ],
    sustainableTitle: [
        'Sustainability',
        'Nachhaltigkeit'
    ],
    sustainableText: [
        'The servers run on renewable energy which makes the use more environmentally friendly.',
        'Die Server laufen ausschließlich Storm aus erneuerbaren Energiequellen, wodurch die Nutzung umweltfreundlicher ist.'
    ],
    platformsTitle: [
        'Platform independent',
        'Platformunabhängig'
    ],
    platformsText: [
        'Appointa runs equally on every device - as an app on your smartphone or in your webbrowser. Everyone can easily access your votings anywhere at any time.',
        'Appointa läuft überall gleich - egal ob im Webbrowser oder als App auf dem Smartphone. So kann jeder Teilnehmer immer und überall auf ihre Abstimmung zugreifen.'
    ]
}

export default def;