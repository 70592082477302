import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list_header, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }),
        (_ctx.users.length <= 0)
          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.lang.t("noVotes")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
          return (_openBlock(), _createBlock(_component_ion_item, { key: user }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(user), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.creator !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_text, { color: "medium" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.lang.t('addedBy')) + " " + _toDisplayString(_ctx.creator), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}