import { PublicServerInstanceConfiguration, PublicServerConfiguration } from "../../../server/src/config";
import { alertController, loadingController } from "@ionic/vue";
import LanguageService, { Translator } from "@/lib/service/LanguageService";
import { Capacitor } from "@capacitor/core";

class HTTPClientService {
    private readonly controllerURL: string;
    private config?: PublicServerConfiguration;
    private server?: PublicServerInstanceConfiguration;
    private langError: Translator;
    private lang: Translator;
    private loadingDialog?: HTMLIonLoadingElement;
    private onConfigLoad: any;

    configLoaded: Promise<any>;

    constructor() {
        this.controllerURL = this.isPrivate() ? 'http://localhost:8200' : 'https://control.appointa.net';
        this.langError = LanguageService.getTranslator('net/error');
        this.lang = LanguageService.getTranslator('net/generic');
        this.configLoaded = new Promise((resolve) => {
            this.onConfigLoad = resolve;
        });
        this.init();
    }

    isPrivate(): boolean {
        return (window.location.hostname === 'localhost' && Capacitor.getPlatform() === 'web')
    }

    async get(url: string): Promise<object> {
        const result = await fetch(`${this.controllerURL}/${url}`);
        return await result.json();
    }

    async post(url: string, body?: object): Promise<object> {
        const result = await fetch(`${this.controllerURL}/${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: body ? JSON.stringify(body) : ''
        });
        return await result.json();
    }

    async showNoConnection() {
        if (!window.location.href.includes('screenshot-phone')) {
            const alert = await alertController.create({
                header: this.langError.t('error'),
                subHeader: this.langError.t('serverConnection'),
                message: this.langError.t('controllerOffline'),
                buttons: [{ text: 'OK', role: 'ok' }, { text: this.langError.t('tryAgain'), role: 'tryAgain' }]
            });
            await alert.present();
            return (await alert.onDidDismiss()).role;
        }
    }

    async showLoading(status: boolean) {
        if (status) {
            this.loadingDialog = await loadingController.create({ duration: 20000, backdropDismiss: false, message: this.lang.t('connecting') });
            if (this.loadingDialog !== undefined) {
                await this.loadingDialog.present();
            }
        } else if (this.loadingDialog !== undefined) {
            this.loadingDialog.dismiss();
        }
    }

    async init() {
        try {
            await this.showLoading(true);
            this.config = await this.post('config', {
                event: window.location.hash.length > 10 ? window.location.hash.substr(1) : undefined
            }) as PublicServerConfiguration;
            this.onConfigLoad();
        } catch (error) {
            console.error(error);
            this.showNoConnection().then(result => {
                if (result === 'tryAgain') {
                    this.init();
                }
            });
        }
        await this.showLoading(false);
    }



    getRandomInstance(): PublicServerInstanceConfiguration | undefined {
        if (this.config !== undefined) {
            return this.config.instances[(Math.random() * this.config.instances.length) | 0];
        } else {
            return undefined;
        }
    }
}

export default new HTTPClientService;