
import { defineComponent } from "vue";
import LanguageService from "../../../lib/service/LanguageService";

import FloatingHeader from "../splash/FloatingHeader.vue";

import DateModule from "../../modules/date/DateModule.vue";
import ChecklistModule from "../../modules/checklist/ChecklistModule.vue";
import VoteModule from "../../modules/vote/VoteModule.vue";
import DiscussModule from "../../modules/discuss/DiscussModule.vue";
import MapModule from "../../modules/map/MapModule.vue";
import FeatureEntry from "./FeatureEntry.vue";

export default defineComponent({
  name: "Features",
  components: {
    FloatingHeader,
    DateModule,
    ChecklistModule,
    VoteModule,
    DiscussModule,
    MapModule,
    FeatureEntry
  },
  setup() {
    return {
      lang: LanguageService.getTranslator("home/features"),
    };
  },
});
