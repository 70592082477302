import { ModuleLanguageDefinition } from "../../LanguageService";

const def: ModuleLanguageDefinition = {
    title: [
        'Map',
        'Karte'
    ],
    desc: [
        'Show a position on the map, for example the place where to meet.',
        'Zeigen sie eine Position auf der Karte, zum Beispiel den Treffpunkt.'
    ],
    titleTooShort: [
        'The title is too short.',
        'Der Titel ist zu kurz.'
    ],
    titleLabel: [
        'What is the title of the map?',
        'Wie ist der Titel der Karte?'
    ],
    locationQuery: [
        'Location input',
        'Position eingeben'
    ],
    searchLocation: [
        'Enter location or address...',
        'Ort oder Adresse eingeben...'
    ],
    location: [
        'Location',
        'Position'
    ],
    preview: [
        'Preview',
        'Vorschau'
    ],
    previewHint: [
        'You can only interact with the map after you created the Appointa.',
        'Sie können erst mit der Karte interagieren, nachdem sie die Erstellung abgeschlossen haben.'
    ],
    locationError: [
        'The location could not be determined. Maybe the required permission was not granted.',
        'Die aktuelle Position konnte nicht bestimmt werden. Möglicherweise wurden die benötigten Berechtigungen nicht erteilt.'
    ]
}

export default def;