
import { computed, defineComponent, onBeforeMount, Ref, ref } from "vue";

import {
  checkmark,
  close,
  help,
  person,
  closeCircle,
  checkmarkCircle,
  helpCircle,
  timeOutline,
  caretDown,
} from "ionicons/icons";

import {
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonIcon,
  IonButton,
  IonChip,
  popoverController,
} from "@ionic/vue";

import EventModule from "../EventModule.vue";

import EventService from "../../../lib/service/EventService";
import LanguageService from "../../../lib/service/LanguageService";
import WebSocketClientService from "../../../net/WebSocketClientService";
import { DateVoteRequest } from "../../../../../shared/protocol/request/Date";
import VotersList from "./VotersList.vue";
import { DateInfo } from "../../../../../shared/protocol/Date";
import DateTable from "./DateTable.vue";
import ContentFilter from "../../generic/ContentFilter.vue";
import { DynamicDateData } from "../../../../../shared/protocol/EventData";

export default defineComponent({
  name: "DateModule",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonIcon,
    IonButton,
    IonChip,
    EventModule,
    DateTable,
    ContentFilter,
  },
  props: {
    example: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const lang = LanguageService.getTranslator("module/date").add(
      LanguageService.getTranslator("generic")
    );
    const examples: Ref<any> = ref(undefined);

    const weightedVoteScore = (index: number) => {
      if (EventService.data.event && EventService.data.event.dynamic.date) {
        return (
          EventService.data.event.dynamic.date.votes[index].y.length +
          EventService.data.event.dynamic.date.votes[index].m.length * 0.5
        );
      } else {
        return 0;
      }
    };

    const bestScore = computed(() => {
      let best = 0;
      if (EventService.data.event && EventService.data.event.dynamic.date) {
        for (
          let i = 0;
          i < Object.keys(EventService.data.event.dynamic.date.votes).length;
          i++
        ) {
          const score = weightedVoteScore(i);
          if (score > best) best = score;
        }
      }
      return best;
    });

    const username = computed(() => {
      if (examples.value !== undefined) {
        return examples.value[LanguageService.lang].user;
      } else {
        return EventService.data.name;
      }
    });

    const disabled = computed(() => !WebSocketClientService.status.online);

    const dates = computed<DateInfo[]>(() => {
      if (props.example && examples.value !== undefined) {
        return examples.value[LanguageService.lang].dates;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.date !== undefined
      ) {
        return EventService.data.event.static.config.date.dates;
      } else {
        return [];
      }
    });

    const dynamic = computed<DynamicDateData>(() => {
      if (examples.value !== undefined) {
        return examples.value[LanguageService.lang].dynamic;
      } else if (
        EventService.data.event !== undefined &&
        EventService.data.event.dynamic.date !== undefined
      ) {
        return EventService.data.event.dynamic.date;
      }
      return { votes: [] };
    });

    const vote = (index: number, value: "y" | "n" | "m") => {
      const req: DateVoteRequest = {
        index,
        vote: value,
      };
      WebSocketClientService.sendRequest("date:vote", req);
    };

    const voteScore = (index: number) => {
      if (EventService.data.event && EventService.data.event.dynamic.date) {
        return (
          EventService.data.event.dynamic.date.votes[index].y.length +
          EventService.data.event.dynamic.date.votes[index].m.length
        );
      } else {
        return 0;
      }
    };

    const hasVoted = (index: number, vote: "y" | "n" | "m") => {
      if (EventService.data.event && EventService.data.event.dynamic.date) {
        return EventService.data.event.dynamic.date.votes[index][vote].includes(
          EventService.data.name
        );
      } else {
        return false;
      }
    };

    const showVoterList = async (event: Event, index: number) => {
      const popover = await popoverController.create({
        event,
        component: VotersList,
        componentProps: {
          index,
        },
      });
      await popover.present();
    };

    onBeforeMount(() => {
      if (props.example) {
        examples.value = require("./example").default;
      }
    });

    return {
      lang,
      EventService,
      examples,
      checkmark,
      close,
      help,
      person,
      closeCircle,
      checkmarkCircle,
      helpCircle,
      timeOutline,
      caretDown,
      weightedVoteScore,
      bestScore,
      username,
      disabled,
      dates,
      dynamic,
      vote,
      voteScore,
      hasVoted,
      showVoterList
    };
  },
});
