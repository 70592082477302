import { ModuleLanguageDefinition } from "../../LanguageService";

const def: ModuleLanguageDefinition = {
    title: [
        'Voting poll',
        'Abstimmung'
    ],
    desc: [
        'Let the participants vote about a question. You can enter the answers.',
        'Lassen sie die Teilnehmer über eine Frage abstimmen. Die Antworten können sie vorgeben.'
    ],
    titleTooShort: [
        'The title is too short.',
        'Der Titel ist zu kurz.'
    ],
    showResults: [
        'Show results',
        'Ergebnisse anzeigen'
    ],
    showingResults: [
        'The results will be shown before voting.',
        'Die Ergebnisse werden schon vor dem Abstimmen angezeigt.'
    ],
    hidingResults: [
        'The results will be hidden until voting.',
        'Die Ergebnisse werden erst nach dem Abstimmen angezeigt.'
    ],
    answers: [
        'Possible answers',
        'Mögliche Antworten'
    ],
    newAnswer: [
        'New answer',
        'Neue Antwort'
    ],
    inputAnswer: [
        'Input answer...',
        'Antwort eingeben...'
    ],
    noAnswers: [
        'No answers yet.',
        'Noch keine Möglichkeiten.'
    ],
    titleLabel: [
        'What is the name of your poll?',
        'Wie ist der Titel ihrer Abstimmung?'
    ],
    tooFewOptions: [
        'Add at least 2 answers.',
        'Fügen sie mindestens zwei Möglichkeiten hinzu.'
    ],
    multipleChoice: [
        'Multiple choices',
        'Mehrfachauswahl möglich'
    ],
    noVotes: [
        'No votes',
        'Keine Stimmen'
    ],
    results: [
        'Vote results',
        'Ergebnisse'
    ],
    voteFirst: [
        'The results will be shown after you have voted.',
        'Die Ergebnisse sind erst sichtbar nachdem sie abgestimmt haben'
    ],
    usersCanAdd: [
        'Users can add answers',
        'Teilnehmer können Antworten hinzufügen'
    ],
    newOption: [
        'New option',
        'Neue Antwort'
    ],
    alreadyExists: [
        'Already exists',
        'Bereits vorhanden'
    ],
    deleteHeader: [
        'Delete answer',
        'Antwort entfernen'
    ],
    confirmDelete: [
        'Are you sure to delete this answer?',
        'Möchten sie diese Antwort wirklich löschen?'
    ],
    confirm: [
        'Confirm',
        'Bestätigen'
    ],
    cancel: [
        'Cancel',
        'Abbrechen'
    ],
    addedBy: [
        'Added by',
        'Hinzugefügt von'
    ],
    noEntries: [
        'No answers have been added yet.',
        'Es wurden noch keine Antworten hinzugefügt.'
    ]
}

export default def;