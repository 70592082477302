import { LanguageDefinition } from "../../LanguageService";

const def: LanguageDefinition = {
    title: [
        'Features',
        'Funktionen'
    ],
    noModuleSelected: [
        'Select at least one feature',
        'Wähle mindestens eine Funktion'
    ],
    interactive: [
        'Interactive',
        'Interaktiv'
    ],
    description: [
        'Select which features you want to enable.',
        'Wählen sie, welche Funktionen sie aktivieren möchten.'
    ]
}

export default def;